/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as PaywallImport } from './routes/_paywall'
import { Route as AuthImport } from './routes/_auth'
import { Route as AppImport } from './routes/_app'
import { Route as IndexImport } from './routes/index'
import { Route as PaywallPaywallImport } from './routes/_paywall/paywall'
import { Route as AuthSignupImport } from './routes/_auth/signup'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AppPageImport } from './routes/_app/page'
import { Route as AppFilebrowseImport } from './routes/_app/filebrowse'
import { Route as AppDashboardImport } from './routes/_app/dashboard'
import { Route as AppV3Import } from './routes/_app/_v3'
import { Route as AppSettingsIndexImport } from './routes/_app/settings/index'
import { Route as AppAnalyzeIndexImport } from './routes/_app/analyze.index'
import { Route as AppSettingsManageUsersImport } from './routes/_app/settings/manage-users'
import { Route as AppSettingsDevImport } from './routes/_app/settings/dev'
import { Route as AppSettingsBillingImport } from './routes/_app/settings/billing'
import { Route as AppSettingsAboutImport } from './routes/_app/settings/about'
import { Route as AppAnalyzeProjectidImport } from './routes/_app/analyze.$project_id_'
import { Route as AppAdminAdminImport } from './routes/_app/admin/_admin'
import { Route as AppAdminAdminIndexImport } from './routes/_app/admin/_admin/index'
import { Route as AppAnalyzeProjectidDocumentidImport } from './routes/_app/analyze.$project_id.$document_id'
import { Route as AppAdminAdminChatMessagesImport } from './routes/_app/admin/_admin/chat-messages'
import { Route as AppAdminAdminAccountsAccountsImport } from './routes/_app/admin/_admin/accounts/_accounts'
import { Route as AppV3ProjectProjectIDProjectImport } from './routes/_app/_v3/project.$projectID/_project'
import { Route as AppAdminAdminAccountsAccountsIndexImport } from './routes/_app/admin/_admin/accounts/_accounts.index'
import { Route as AppV3ProjectProjectIDProjectIndexImport } from './routes/_app/_v3/project.$projectID/_project.index'
import { Route as AppAdminAdminAccountsAccountsCreateImport } from './routes/_app/admin/_admin/accounts/_accounts.create'
import { Route as AppAdminAdminAccountsAccountsIdImport } from './routes/_app/admin/_admin/accounts/_accounts.$id'
import { Route as AppV3ProjectProjectIDProjectDocDocumentIDImport } from './routes/_app/_v3/project.$projectID/_project.doc.$documentID'
import { Route as AppV3ProjectProjectIDProjectCComponentIDImport } from './routes/_app/_v3/project.$projectID/_project.c.$componentID'

// Create Virtual Routes

const AppAdminImport = createFileRoute('/_app/admin')()
const AppAdminAdminAccountsImport = createFileRoute(
  '/_app/admin/_admin/accounts',
)()
const AppV3ProjectProjectIDImport = createFileRoute(
  '/_app/_v3/project/$projectID',
)()

// Create/Update Routes

const PaywallRoute = PaywallImport.update({
  id: '/_paywall',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AppAdminRoute = AppAdminImport.update({
  id: '/admin',
  path: '/admin',
  getParentRoute: () => AppRoute,
} as any)

const PaywallPaywallRoute = PaywallPaywallImport.update({
  id: '/paywall',
  path: '/paywall',
  getParentRoute: () => PaywallRoute,
} as any)

const AuthSignupRoute = AuthSignupImport.update({
  id: '/signup',
  path: '/signup',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AppPageRoute = AppPageImport.update({
  id: '/page',
  path: '/page',
  getParentRoute: () => AppRoute,
} as any)

const AppFilebrowseRoute = AppFilebrowseImport.update({
  id: '/filebrowse',
  path: '/filebrowse',
  getParentRoute: () => AppRoute,
} as any)

const AppDashboardRoute = AppDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AppRoute,
} as any)

const AppV3Route = AppV3Import.update({
  id: '/_v3',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsIndexRoute = AppSettingsIndexImport.update({
  id: '/settings/',
  path: '/settings/',
  getParentRoute: () => AppRoute,
} as any)

const AppAnalyzeIndexRoute = AppAnalyzeIndexImport.update({
  id: '/analyze/',
  path: '/analyze/',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsManageUsersRoute = AppSettingsManageUsersImport.update({
  id: '/settings/manage-users',
  path: '/settings/manage-users',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsDevRoute = AppSettingsDevImport.update({
  id: '/settings/dev',
  path: '/settings/dev',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsBillingRoute = AppSettingsBillingImport.update({
  id: '/settings/billing',
  path: '/settings/billing',
  getParentRoute: () => AppRoute,
} as any)

const AppSettingsAboutRoute = AppSettingsAboutImport.update({
  id: '/settings/about',
  path: '/settings/about',
  getParentRoute: () => AppRoute,
} as any)

const AppAnalyzeProjectidRoute = AppAnalyzeProjectidImport.update({
  id: '/analyze/$project_id_',
  path: '/analyze/$project_id',
  getParentRoute: () => AppRoute,
} as any)

const AppAdminAdminRoute = AppAdminAdminImport.update({
  id: '/_admin',
  getParentRoute: () => AppAdminRoute,
} as any)

const AppAdminAdminAccountsRoute = AppAdminAdminAccountsImport.update({
  id: '/accounts',
  path: '/accounts',
  getParentRoute: () => AppAdminAdminRoute,
} as any)

const AppV3ProjectProjectIDRoute = AppV3ProjectProjectIDImport.update({
  id: '/project/$projectID',
  path: '/project/$projectID',
  getParentRoute: () => AppV3Route,
} as any)

const AppAdminAdminIndexRoute = AppAdminAdminIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppAdminAdminRoute,
} as any)

const AppAnalyzeProjectidDocumentidRoute =
  AppAnalyzeProjectidDocumentidImport.update({
    id: '/analyze/$project_id/$document_id',
    path: '/analyze/$project_id/$document_id',
    getParentRoute: () => AppRoute,
  } as any)

const AppAdminAdminChatMessagesRoute = AppAdminAdminChatMessagesImport.update({
  id: '/chat-messages',
  path: '/chat-messages',
  getParentRoute: () => AppAdminAdminRoute,
} as any)

const AppAdminAdminAccountsAccountsRoute =
  AppAdminAdminAccountsAccountsImport.update({
    id: '/_accounts',
    getParentRoute: () => AppAdminAdminAccountsRoute,
  } as any)

const AppV3ProjectProjectIDProjectRoute =
  AppV3ProjectProjectIDProjectImport.update({
    id: '/_project',
    getParentRoute: () => AppV3ProjectProjectIDRoute,
  } as any)

const AppAdminAdminAccountsAccountsIndexRoute =
  AppAdminAdminAccountsAccountsIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppAdminAdminAccountsAccountsRoute,
  } as any)

const AppV3ProjectProjectIDProjectIndexRoute =
  AppV3ProjectProjectIDProjectIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppV3ProjectProjectIDProjectRoute,
  } as any)

const AppAdminAdminAccountsAccountsCreateRoute =
  AppAdminAdminAccountsAccountsCreateImport.update({
    id: '/create',
    path: '/create',
    getParentRoute: () => AppAdminAdminAccountsAccountsRoute,
  } as any)

const AppAdminAdminAccountsAccountsIdRoute =
  AppAdminAdminAccountsAccountsIdImport.update({
    id: '/$id',
    path: '/$id',
    getParentRoute: () => AppAdminAdminAccountsAccountsRoute,
  } as any)

const AppV3ProjectProjectIDProjectDocDocumentIDRoute =
  AppV3ProjectProjectIDProjectDocDocumentIDImport.update({
    id: '/doc/$documentID',
    path: '/doc/$documentID',
    getParentRoute: () => AppV3ProjectProjectIDProjectRoute,
  } as any)

const AppV3ProjectProjectIDProjectCComponentIDRoute =
  AppV3ProjectProjectIDProjectCComponentIDImport.update({
    id: '/c/$componentID',
    path: '/c/$componentID',
    getParentRoute: () => AppV3ProjectProjectIDProjectRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/_paywall': {
      id: '/_paywall'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof PaywallImport
      parentRoute: typeof rootRoute
    }
    '/_app/_v3': {
      id: '/_app/_v3'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppV3Import
      parentRoute: typeof AppImport
    }
    '/_app/dashboard': {
      id: '/_app/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AppDashboardImport
      parentRoute: typeof AppImport
    }
    '/_app/filebrowse': {
      id: '/_app/filebrowse'
      path: '/filebrowse'
      fullPath: '/filebrowse'
      preLoaderRoute: typeof AppFilebrowseImport
      parentRoute: typeof AppImport
    }
    '/_app/page': {
      id: '/_app/page'
      path: '/page'
      fullPath: '/page'
      preLoaderRoute: typeof AppPageImport
      parentRoute: typeof AppImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/signup': {
      id: '/_auth/signup'
      path: '/signup'
      fullPath: '/signup'
      preLoaderRoute: typeof AuthSignupImport
      parentRoute: typeof AuthImport
    }
    '/_paywall/paywall': {
      id: '/_paywall/paywall'
      path: '/paywall'
      fullPath: '/paywall'
      preLoaderRoute: typeof PaywallPaywallImport
      parentRoute: typeof PaywallImport
    }
    '/_app/admin': {
      id: '/_app/admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AppAdminImport
      parentRoute: typeof AppImport
    }
    '/_app/admin/_admin': {
      id: '/_app/admin/_admin'
      path: '/admin'
      fullPath: '/admin'
      preLoaderRoute: typeof AppAdminAdminImport
      parentRoute: typeof AppAdminRoute
    }
    '/_app/analyze/$project_id_': {
      id: '/_app/analyze/$project_id_'
      path: '/analyze/$project_id'
      fullPath: '/analyze/$project_id'
      preLoaderRoute: typeof AppAnalyzeProjectidImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/about': {
      id: '/_app/settings/about'
      path: '/settings/about'
      fullPath: '/settings/about'
      preLoaderRoute: typeof AppSettingsAboutImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/billing': {
      id: '/_app/settings/billing'
      path: '/settings/billing'
      fullPath: '/settings/billing'
      preLoaderRoute: typeof AppSettingsBillingImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/dev': {
      id: '/_app/settings/dev'
      path: '/settings/dev'
      fullPath: '/settings/dev'
      preLoaderRoute: typeof AppSettingsDevImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/manage-users': {
      id: '/_app/settings/manage-users'
      path: '/settings/manage-users'
      fullPath: '/settings/manage-users'
      preLoaderRoute: typeof AppSettingsManageUsersImport
      parentRoute: typeof AppImport
    }
    '/_app/analyze/': {
      id: '/_app/analyze/'
      path: '/analyze'
      fullPath: '/analyze'
      preLoaderRoute: typeof AppAnalyzeIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/settings/': {
      id: '/_app/settings/'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AppSettingsIndexImport
      parentRoute: typeof AppImport
    }
    '/_app/admin/_admin/chat-messages': {
      id: '/_app/admin/_admin/chat-messages'
      path: '/chat-messages'
      fullPath: '/admin/chat-messages'
      preLoaderRoute: typeof AppAdminAdminChatMessagesImport
      parentRoute: typeof AppAdminAdminImport
    }
    '/_app/analyze/$project_id/$document_id': {
      id: '/_app/analyze/$project_id/$document_id'
      path: '/analyze/$project_id/$document_id'
      fullPath: '/analyze/$project_id/$document_id'
      preLoaderRoute: typeof AppAnalyzeProjectidDocumentidImport
      parentRoute: typeof AppImport
    }
    '/_app/admin/_admin/': {
      id: '/_app/admin/_admin/'
      path: '/'
      fullPath: '/admin/'
      preLoaderRoute: typeof AppAdminAdminIndexImport
      parentRoute: typeof AppAdminAdminImport
    }
    '/_app/_v3/project/$projectID': {
      id: '/_app/_v3/project/$projectID'
      path: '/project/$projectID'
      fullPath: '/project/$projectID'
      preLoaderRoute: typeof AppV3ProjectProjectIDImport
      parentRoute: typeof AppV3Import
    }
    '/_app/_v3/project/$projectID/_project': {
      id: '/_app/_v3/project/$projectID/_project'
      path: '/project/$projectID'
      fullPath: '/project/$projectID'
      preLoaderRoute: typeof AppV3ProjectProjectIDProjectImport
      parentRoute: typeof AppV3ProjectProjectIDRoute
    }
    '/_app/admin/_admin/accounts': {
      id: '/_app/admin/_admin/accounts'
      path: '/accounts'
      fullPath: '/admin/accounts'
      preLoaderRoute: typeof AppAdminAdminAccountsImport
      parentRoute: typeof AppAdminAdminImport
    }
    '/_app/admin/_admin/accounts/_accounts': {
      id: '/_app/admin/_admin/accounts/_accounts'
      path: '/accounts'
      fullPath: '/admin/accounts'
      preLoaderRoute: typeof AppAdminAdminAccountsAccountsImport
      parentRoute: typeof AppAdminAdminAccountsRoute
    }
    '/_app/admin/_admin/accounts/_accounts/$id': {
      id: '/_app/admin/_admin/accounts/_accounts/$id'
      path: '/$id'
      fullPath: '/admin/accounts/$id'
      preLoaderRoute: typeof AppAdminAdminAccountsAccountsIdImport
      parentRoute: typeof AppAdminAdminAccountsAccountsImport
    }
    '/_app/admin/_admin/accounts/_accounts/create': {
      id: '/_app/admin/_admin/accounts/_accounts/create'
      path: '/create'
      fullPath: '/admin/accounts/create'
      preLoaderRoute: typeof AppAdminAdminAccountsAccountsCreateImport
      parentRoute: typeof AppAdminAdminAccountsAccountsImport
    }
    '/_app/_v3/project/$projectID/_project/': {
      id: '/_app/_v3/project/$projectID/_project/'
      path: '/'
      fullPath: '/project/$projectID/'
      preLoaderRoute: typeof AppV3ProjectProjectIDProjectIndexImport
      parentRoute: typeof AppV3ProjectProjectIDProjectImport
    }
    '/_app/admin/_admin/accounts/_accounts/': {
      id: '/_app/admin/_admin/accounts/_accounts/'
      path: '/'
      fullPath: '/admin/accounts/'
      preLoaderRoute: typeof AppAdminAdminAccountsAccountsIndexImport
      parentRoute: typeof AppAdminAdminAccountsAccountsImport
    }
    '/_app/_v3/project/$projectID/_project/c/$componentID': {
      id: '/_app/_v3/project/$projectID/_project/c/$componentID'
      path: '/c/$componentID'
      fullPath: '/project/$projectID/c/$componentID'
      preLoaderRoute: typeof AppV3ProjectProjectIDProjectCComponentIDImport
      parentRoute: typeof AppV3ProjectProjectIDProjectImport
    }
    '/_app/_v3/project/$projectID/_project/doc/$documentID': {
      id: '/_app/_v3/project/$projectID/_project/doc/$documentID'
      path: '/doc/$documentID'
      fullPath: '/project/$projectID/doc/$documentID'
      preLoaderRoute: typeof AppV3ProjectProjectIDProjectDocDocumentIDImport
      parentRoute: typeof AppV3ProjectProjectIDProjectImport
    }
  }
}

// Create and export the route tree

interface AppV3ProjectProjectIDProjectRouteChildren {
  AppV3ProjectProjectIDProjectIndexRoute: typeof AppV3ProjectProjectIDProjectIndexRoute
  AppV3ProjectProjectIDProjectCComponentIDRoute: typeof AppV3ProjectProjectIDProjectCComponentIDRoute
  AppV3ProjectProjectIDProjectDocDocumentIDRoute: typeof AppV3ProjectProjectIDProjectDocDocumentIDRoute
}

const AppV3ProjectProjectIDProjectRouteChildren: AppV3ProjectProjectIDProjectRouteChildren =
  {
    AppV3ProjectProjectIDProjectIndexRoute:
      AppV3ProjectProjectIDProjectIndexRoute,
    AppV3ProjectProjectIDProjectCComponentIDRoute:
      AppV3ProjectProjectIDProjectCComponentIDRoute,
    AppV3ProjectProjectIDProjectDocDocumentIDRoute:
      AppV3ProjectProjectIDProjectDocDocumentIDRoute,
  }

const AppV3ProjectProjectIDProjectRouteWithChildren =
  AppV3ProjectProjectIDProjectRoute._addFileChildren(
    AppV3ProjectProjectIDProjectRouteChildren,
  )

interface AppV3ProjectProjectIDRouteChildren {
  AppV3ProjectProjectIDProjectRoute: typeof AppV3ProjectProjectIDProjectRouteWithChildren
}

const AppV3ProjectProjectIDRouteChildren: AppV3ProjectProjectIDRouteChildren = {
  AppV3ProjectProjectIDProjectRoute:
    AppV3ProjectProjectIDProjectRouteWithChildren,
}

const AppV3ProjectProjectIDRouteWithChildren =
  AppV3ProjectProjectIDRoute._addFileChildren(
    AppV3ProjectProjectIDRouteChildren,
  )

interface AppV3RouteChildren {
  AppV3ProjectProjectIDRoute: typeof AppV3ProjectProjectIDRouteWithChildren
}

const AppV3RouteChildren: AppV3RouteChildren = {
  AppV3ProjectProjectIDRoute: AppV3ProjectProjectIDRouteWithChildren,
}

const AppV3RouteWithChildren = AppV3Route._addFileChildren(AppV3RouteChildren)

interface AppAdminAdminAccountsAccountsRouteChildren {
  AppAdminAdminAccountsAccountsIdRoute: typeof AppAdminAdminAccountsAccountsIdRoute
  AppAdminAdminAccountsAccountsCreateRoute: typeof AppAdminAdminAccountsAccountsCreateRoute
  AppAdminAdminAccountsAccountsIndexRoute: typeof AppAdminAdminAccountsAccountsIndexRoute
}

const AppAdminAdminAccountsAccountsRouteChildren: AppAdminAdminAccountsAccountsRouteChildren =
  {
    AppAdminAdminAccountsAccountsIdRoute: AppAdminAdminAccountsAccountsIdRoute,
    AppAdminAdminAccountsAccountsCreateRoute:
      AppAdminAdminAccountsAccountsCreateRoute,
    AppAdminAdminAccountsAccountsIndexRoute:
      AppAdminAdminAccountsAccountsIndexRoute,
  }

const AppAdminAdminAccountsAccountsRouteWithChildren =
  AppAdminAdminAccountsAccountsRoute._addFileChildren(
    AppAdminAdminAccountsAccountsRouteChildren,
  )

interface AppAdminAdminAccountsRouteChildren {
  AppAdminAdminAccountsAccountsRoute: typeof AppAdminAdminAccountsAccountsRouteWithChildren
}

const AppAdminAdminAccountsRouteChildren: AppAdminAdminAccountsRouteChildren = {
  AppAdminAdminAccountsAccountsRoute:
    AppAdminAdminAccountsAccountsRouteWithChildren,
}

const AppAdminAdminAccountsRouteWithChildren =
  AppAdminAdminAccountsRoute._addFileChildren(
    AppAdminAdminAccountsRouteChildren,
  )

interface AppAdminAdminRouteChildren {
  AppAdminAdminChatMessagesRoute: typeof AppAdminAdminChatMessagesRoute
  AppAdminAdminIndexRoute: typeof AppAdminAdminIndexRoute
  AppAdminAdminAccountsRoute: typeof AppAdminAdminAccountsRouteWithChildren
}

const AppAdminAdminRouteChildren: AppAdminAdminRouteChildren = {
  AppAdminAdminChatMessagesRoute: AppAdminAdminChatMessagesRoute,
  AppAdminAdminIndexRoute: AppAdminAdminIndexRoute,
  AppAdminAdminAccountsRoute: AppAdminAdminAccountsRouteWithChildren,
}

const AppAdminAdminRouteWithChildren = AppAdminAdminRoute._addFileChildren(
  AppAdminAdminRouteChildren,
)

interface AppAdminRouteChildren {
  AppAdminAdminRoute: typeof AppAdminAdminRouteWithChildren
}

const AppAdminRouteChildren: AppAdminRouteChildren = {
  AppAdminAdminRoute: AppAdminAdminRouteWithChildren,
}

const AppAdminRouteWithChildren = AppAdminRoute._addFileChildren(
  AppAdminRouteChildren,
)

interface AppRouteChildren {
  AppV3Route: typeof AppV3RouteWithChildren
  AppDashboardRoute: typeof AppDashboardRoute
  AppFilebrowseRoute: typeof AppFilebrowseRoute
  AppPageRoute: typeof AppPageRoute
  AppAdminRoute: typeof AppAdminRouteWithChildren
  AppAnalyzeProjectidRoute: typeof AppAnalyzeProjectidRoute
  AppSettingsAboutRoute: typeof AppSettingsAboutRoute
  AppSettingsBillingRoute: typeof AppSettingsBillingRoute
  AppSettingsDevRoute: typeof AppSettingsDevRoute
  AppSettingsManageUsersRoute: typeof AppSettingsManageUsersRoute
  AppAnalyzeIndexRoute: typeof AppAnalyzeIndexRoute
  AppSettingsIndexRoute: typeof AppSettingsIndexRoute
  AppAnalyzeProjectidDocumentidRoute: typeof AppAnalyzeProjectidDocumentidRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppV3Route: AppV3RouteWithChildren,
  AppDashboardRoute: AppDashboardRoute,
  AppFilebrowseRoute: AppFilebrowseRoute,
  AppPageRoute: AppPageRoute,
  AppAdminRoute: AppAdminRouteWithChildren,
  AppAnalyzeProjectidRoute: AppAnalyzeProjectidRoute,
  AppSettingsAboutRoute: AppSettingsAboutRoute,
  AppSettingsBillingRoute: AppSettingsBillingRoute,
  AppSettingsDevRoute: AppSettingsDevRoute,
  AppSettingsManageUsersRoute: AppSettingsManageUsersRoute,
  AppAnalyzeIndexRoute: AppAnalyzeIndexRoute,
  AppSettingsIndexRoute: AppSettingsIndexRoute,
  AppAnalyzeProjectidDocumentidRoute: AppAnalyzeProjectidDocumentidRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface AuthRouteChildren {
  AuthLoginRoute: typeof AuthLoginRoute
  AuthSignupRoute: typeof AuthSignupRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginRoute: AuthLoginRoute,
  AuthSignupRoute: AuthSignupRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

interface PaywallRouteChildren {
  PaywallPaywallRoute: typeof PaywallPaywallRoute
}

const PaywallRouteChildren: PaywallRouteChildren = {
  PaywallPaywallRoute: PaywallPaywallRoute,
}

const PaywallRouteWithChildren =
  PaywallRoute._addFileChildren(PaywallRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AppV3RouteWithChildren
  '/dashboard': typeof AppDashboardRoute
  '/filebrowse': typeof AppFilebrowseRoute
  '/page': typeof AppPageRoute
  '/login': typeof AuthLoginRoute
  '/signup': typeof AuthSignupRoute
  '/paywall': typeof PaywallPaywallRoute
  '/admin': typeof AppAdminAdminRouteWithChildren
  '/analyze/$project_id': typeof AppAnalyzeProjectidRoute
  '/settings/about': typeof AppSettingsAboutRoute
  '/settings/billing': typeof AppSettingsBillingRoute
  '/settings/dev': typeof AppSettingsDevRoute
  '/settings/manage-users': typeof AppSettingsManageUsersRoute
  '/analyze': typeof AppAnalyzeIndexRoute
  '/settings': typeof AppSettingsIndexRoute
  '/admin/chat-messages': typeof AppAdminAdminChatMessagesRoute
  '/analyze/$project_id/$document_id': typeof AppAnalyzeProjectidDocumentidRoute
  '/admin/': typeof AppAdminAdminIndexRoute
  '/project/$projectID': typeof AppV3ProjectProjectIDProjectRouteWithChildren
  '/admin/accounts': typeof AppAdminAdminAccountsAccountsRouteWithChildren
  '/admin/accounts/$id': typeof AppAdminAdminAccountsAccountsIdRoute
  '/admin/accounts/create': typeof AppAdminAdminAccountsAccountsCreateRoute
  '/project/$projectID/': typeof AppV3ProjectProjectIDProjectIndexRoute
  '/admin/accounts/': typeof AppAdminAdminAccountsAccountsIndexRoute
  '/project/$projectID/c/$componentID': typeof AppV3ProjectProjectIDProjectCComponentIDRoute
  '/project/$projectID/doc/$documentID': typeof AppV3ProjectProjectIDProjectDocDocumentIDRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AppV3RouteWithChildren
  '/dashboard': typeof AppDashboardRoute
  '/filebrowse': typeof AppFilebrowseRoute
  '/page': typeof AppPageRoute
  '/login': typeof AuthLoginRoute
  '/signup': typeof AuthSignupRoute
  '/paywall': typeof PaywallPaywallRoute
  '/admin': typeof AppAdminAdminIndexRoute
  '/analyze/$project_id': typeof AppAnalyzeProjectidRoute
  '/settings/about': typeof AppSettingsAboutRoute
  '/settings/billing': typeof AppSettingsBillingRoute
  '/settings/dev': typeof AppSettingsDevRoute
  '/settings/manage-users': typeof AppSettingsManageUsersRoute
  '/analyze': typeof AppAnalyzeIndexRoute
  '/settings': typeof AppSettingsIndexRoute
  '/admin/chat-messages': typeof AppAdminAdminChatMessagesRoute
  '/analyze/$project_id/$document_id': typeof AppAnalyzeProjectidDocumentidRoute
  '/project/$projectID': typeof AppV3ProjectProjectIDProjectIndexRoute
  '/admin/accounts': typeof AppAdminAdminAccountsAccountsIndexRoute
  '/admin/accounts/$id': typeof AppAdminAdminAccountsAccountsIdRoute
  '/admin/accounts/create': typeof AppAdminAdminAccountsAccountsCreateRoute
  '/project/$projectID/c/$componentID': typeof AppV3ProjectProjectIDProjectCComponentIDRoute
  '/project/$projectID/doc/$documentID': typeof AppV3ProjectProjectIDProjectDocDocumentIDRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteWithChildren
  '/_auth': typeof AuthRouteWithChildren
  '/_paywall': typeof PaywallRouteWithChildren
  '/_app/_v3': typeof AppV3RouteWithChildren
  '/_app/dashboard': typeof AppDashboardRoute
  '/_app/filebrowse': typeof AppFilebrowseRoute
  '/_app/page': typeof AppPageRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/signup': typeof AuthSignupRoute
  '/_paywall/paywall': typeof PaywallPaywallRoute
  '/_app/admin': typeof AppAdminRouteWithChildren
  '/_app/admin/_admin': typeof AppAdminAdminRouteWithChildren
  '/_app/analyze/$project_id_': typeof AppAnalyzeProjectidRoute
  '/_app/settings/about': typeof AppSettingsAboutRoute
  '/_app/settings/billing': typeof AppSettingsBillingRoute
  '/_app/settings/dev': typeof AppSettingsDevRoute
  '/_app/settings/manage-users': typeof AppSettingsManageUsersRoute
  '/_app/analyze/': typeof AppAnalyzeIndexRoute
  '/_app/settings/': typeof AppSettingsIndexRoute
  '/_app/admin/_admin/chat-messages': typeof AppAdminAdminChatMessagesRoute
  '/_app/analyze/$project_id/$document_id': typeof AppAnalyzeProjectidDocumentidRoute
  '/_app/admin/_admin/': typeof AppAdminAdminIndexRoute
  '/_app/_v3/project/$projectID': typeof AppV3ProjectProjectIDRouteWithChildren
  '/_app/_v3/project/$projectID/_project': typeof AppV3ProjectProjectIDProjectRouteWithChildren
  '/_app/admin/_admin/accounts': typeof AppAdminAdminAccountsRouteWithChildren
  '/_app/admin/_admin/accounts/_accounts': typeof AppAdminAdminAccountsAccountsRouteWithChildren
  '/_app/admin/_admin/accounts/_accounts/$id': typeof AppAdminAdminAccountsAccountsIdRoute
  '/_app/admin/_admin/accounts/_accounts/create': typeof AppAdminAdminAccountsAccountsCreateRoute
  '/_app/_v3/project/$projectID/_project/': typeof AppV3ProjectProjectIDProjectIndexRoute
  '/_app/admin/_admin/accounts/_accounts/': typeof AppAdminAdminAccountsAccountsIndexRoute
  '/_app/_v3/project/$projectID/_project/c/$componentID': typeof AppV3ProjectProjectIDProjectCComponentIDRoute
  '/_app/_v3/project/$projectID/_project/doc/$documentID': typeof AppV3ProjectProjectIDProjectDocDocumentIDRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/dashboard'
    | '/filebrowse'
    | '/page'
    | '/login'
    | '/signup'
    | '/paywall'
    | '/admin'
    | '/analyze/$project_id'
    | '/settings/about'
    | '/settings/billing'
    | '/settings/dev'
    | '/settings/manage-users'
    | '/analyze'
    | '/settings'
    | '/admin/chat-messages'
    | '/analyze/$project_id/$document_id'
    | '/admin/'
    | '/project/$projectID'
    | '/admin/accounts'
    | '/admin/accounts/$id'
    | '/admin/accounts/create'
    | '/project/$projectID/'
    | '/admin/accounts/'
    | '/project/$projectID/c/$componentID'
    | '/project/$projectID/doc/$documentID'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/dashboard'
    | '/filebrowse'
    | '/page'
    | '/login'
    | '/signup'
    | '/paywall'
    | '/admin'
    | '/analyze/$project_id'
    | '/settings/about'
    | '/settings/billing'
    | '/settings/dev'
    | '/settings/manage-users'
    | '/analyze'
    | '/settings'
    | '/admin/chat-messages'
    | '/analyze/$project_id/$document_id'
    | '/project/$projectID'
    | '/admin/accounts'
    | '/admin/accounts/$id'
    | '/admin/accounts/create'
    | '/project/$projectID/c/$componentID'
    | '/project/$projectID/doc/$documentID'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/_auth'
    | '/_paywall'
    | '/_app/_v3'
    | '/_app/dashboard'
    | '/_app/filebrowse'
    | '/_app/page'
    | '/_auth/login'
    | '/_auth/signup'
    | '/_paywall/paywall'
    | '/_app/admin'
    | '/_app/admin/_admin'
    | '/_app/analyze/$project_id_'
    | '/_app/settings/about'
    | '/_app/settings/billing'
    | '/_app/settings/dev'
    | '/_app/settings/manage-users'
    | '/_app/analyze/'
    | '/_app/settings/'
    | '/_app/admin/_admin/chat-messages'
    | '/_app/analyze/$project_id/$document_id'
    | '/_app/admin/_admin/'
    | '/_app/_v3/project/$projectID'
    | '/_app/_v3/project/$projectID/_project'
    | '/_app/admin/_admin/accounts'
    | '/_app/admin/_admin/accounts/_accounts'
    | '/_app/admin/_admin/accounts/_accounts/$id'
    | '/_app/admin/_admin/accounts/_accounts/create'
    | '/_app/_v3/project/$projectID/_project/'
    | '/_app/admin/_admin/accounts/_accounts/'
    | '/_app/_v3/project/$projectID/_project/c/$componentID'
    | '/_app/_v3/project/$projectID/_project/doc/$documentID'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  PaywallRoute: typeof PaywallRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  PaywallRoute: PaywallRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/_auth",
        "/_paywall"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/_v3",
        "/_app/dashboard",
        "/_app/filebrowse",
        "/_app/page",
        "/_app/admin",
        "/_app/analyze/$project_id_",
        "/_app/settings/about",
        "/_app/settings/billing",
        "/_app/settings/dev",
        "/_app/settings/manage-users",
        "/_app/analyze/",
        "/_app/settings/",
        "/_app/analyze/$project_id/$document_id"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login",
        "/_auth/signup"
      ]
    },
    "/_paywall": {
      "filePath": "_paywall.tsx",
      "children": [
        "/_paywall/paywall"
      ]
    },
    "/_app/_v3": {
      "filePath": "_app/_v3.tsx",
      "parent": "/_app",
      "children": [
        "/_app/_v3/project/$projectID"
      ]
    },
    "/_app/dashboard": {
      "filePath": "_app/dashboard.tsx",
      "parent": "/_app"
    },
    "/_app/filebrowse": {
      "filePath": "_app/filebrowse.tsx",
      "parent": "/_app"
    },
    "/_app/page": {
      "filePath": "_app/page.tsx",
      "parent": "/_app"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/signup": {
      "filePath": "_auth/signup.tsx",
      "parent": "/_auth"
    },
    "/_paywall/paywall": {
      "filePath": "_paywall/paywall.tsx",
      "parent": "/_paywall"
    },
    "/_app/admin": {
      "filePath": "_app/admin",
      "parent": "/_app",
      "children": [
        "/_app/admin/_admin"
      ]
    },
    "/_app/admin/_admin": {
      "filePath": "_app/admin/_admin.tsx",
      "parent": "/_app/admin",
      "children": [
        "/_app/admin/_admin/chat-messages",
        "/_app/admin/_admin/",
        "/_app/admin/_admin/accounts"
      ]
    },
    "/_app/analyze/$project_id_": {
      "filePath": "_app/analyze.$project_id_.tsx",
      "parent": "/_app"
    },
    "/_app/settings/about": {
      "filePath": "_app/settings/about.tsx",
      "parent": "/_app"
    },
    "/_app/settings/billing": {
      "filePath": "_app/settings/billing.tsx",
      "parent": "/_app"
    },
    "/_app/settings/dev": {
      "filePath": "_app/settings/dev.tsx",
      "parent": "/_app"
    },
    "/_app/settings/manage-users": {
      "filePath": "_app/settings/manage-users.tsx",
      "parent": "/_app"
    },
    "/_app/analyze/": {
      "filePath": "_app/analyze.index.tsx",
      "parent": "/_app"
    },
    "/_app/settings/": {
      "filePath": "_app/settings/index.tsx",
      "parent": "/_app"
    },
    "/_app/admin/_admin/chat-messages": {
      "filePath": "_app/admin/_admin/chat-messages.tsx",
      "parent": "/_app/admin/_admin"
    },
    "/_app/analyze/$project_id/$document_id": {
      "filePath": "_app/analyze.$project_id.$document_id.tsx",
      "parent": "/_app"
    },
    "/_app/admin/_admin/": {
      "filePath": "_app/admin/_admin/index.tsx",
      "parent": "/_app/admin/_admin"
    },
    "/_app/_v3/project/$projectID": {
      "filePath": "_app/_v3/project.$projectID",
      "parent": "/_app/_v3",
      "children": [
        "/_app/_v3/project/$projectID/_project"
      ]
    },
    "/_app/_v3/project/$projectID/_project": {
      "filePath": "_app/_v3/project.$projectID/_project.tsx",
      "parent": "/_app/_v3/project/$projectID",
      "children": [
        "/_app/_v3/project/$projectID/_project/",
        "/_app/_v3/project/$projectID/_project/c/$componentID",
        "/_app/_v3/project/$projectID/_project/doc/$documentID"
      ]
    },
    "/_app/admin/_admin/accounts": {
      "filePath": "_app/admin/_admin/accounts",
      "parent": "/_app/admin/_admin",
      "children": [
        "/_app/admin/_admin/accounts/_accounts"
      ]
    },
    "/_app/admin/_admin/accounts/_accounts": {
      "filePath": "_app/admin/_admin/accounts/_accounts.tsx",
      "parent": "/_app/admin/_admin/accounts",
      "children": [
        "/_app/admin/_admin/accounts/_accounts/$id",
        "/_app/admin/_admin/accounts/_accounts/create",
        "/_app/admin/_admin/accounts/_accounts/"
      ]
    },
    "/_app/admin/_admin/accounts/_accounts/$id": {
      "filePath": "_app/admin/_admin/accounts/_accounts.$id.tsx",
      "parent": "/_app/admin/_admin/accounts/_accounts"
    },
    "/_app/admin/_admin/accounts/_accounts/create": {
      "filePath": "_app/admin/_admin/accounts/_accounts.create.tsx",
      "parent": "/_app/admin/_admin/accounts/_accounts"
    },
    "/_app/_v3/project/$projectID/_project/": {
      "filePath": "_app/_v3/project.$projectID/_project.index.tsx",
      "parent": "/_app/_v3/project/$projectID/_project"
    },
    "/_app/admin/_admin/accounts/_accounts/": {
      "filePath": "_app/admin/_admin/accounts/_accounts.index.tsx",
      "parent": "/_app/admin/_admin/accounts/_accounts"
    },
    "/_app/_v3/project/$projectID/_project/c/$componentID": {
      "filePath": "_app/_v3/project.$projectID/_project.c.$componentID.tsx",
      "parent": "/_app/_v3/project/$projectID/_project"
    },
    "/_app/_v3/project/$projectID/_project/doc/$documentID": {
      "filePath": "_app/_v3/project.$projectID/_project.doc.$documentID.tsx",
      "parent": "/_app/_v3/project/$projectID/_project"
    }
  }
}
ROUTE_MANIFEST_END */
