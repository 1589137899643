import { useEffect, useRef } from "react";

import { last } from "@/utils/collection";

const MIME_TYPES: Record<string, string> = {
    ico: "image/x-icon",
    png: "image/png",
    svg: "image/svg+xml",
    gif: "image/gif",
};

const getMime = (url: string): string | undefined => {
    const urlParts = url.split(".");
    const extension = last(urlParts);
    if (extension) {
        return MIME_TYPES[extension.toLowerCase()]?.toLowerCase();
    }
    return undefined;
};

const getLinkElement = (url: string): HTMLLinkElement => {
    const el = document.querySelector<HTMLLinkElement>('link[rel*="icon"]');
    if (el) {
        return el;
    }
    const mime_type = getMime(url);
    const element = document.createElement("link");
    element.rel = "icon";
    if (mime_type) {
        element.type = mime_type;
    }
    element.href = url;
    document.querySelector("head")!.appendChild(element);
    return element;
};

export function useFavicon(url: string) {
    const link = useRef<HTMLLinkElement>(getLinkElement(url));

    useEffect(() => {
        const mime_type = getMime(url);
        if (mime_type) {
            link.current.setAttribute("type", mime_type);
        }
        link.current.setAttribute("href", url);
    }, [url]);
}
