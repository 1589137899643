import { AsyncFindingGroup, DocumentInfo } from "@/api/type-extensions";
import { FeatureFlags } from "@/conf/feature-flags";
import { ActionType } from "@/openapi-schema";

export type UUID = string;

export enum AuthLevel {
    logged_out = 0,
    authenticated,
    viewer,
    creator,
    admin,
    superadmin,
}

export enum OnboardingStatusEnum {
    created = 0,
    onboarded,
    deactivated,
}

export enum FileStatus {
    initial = 0,
    fetching_info,
    uploading,
    uploaded,
    processing,
    ready,
    failed,
    rejected,
}

export enum SortingDirection {
    ASC = "asc",
    DESC = "desc",
}

export enum Exchange {
    NYSE = "NYSE",
    NASDAQ = "NASDAQ",
    NYSEMKT = "NYSEMKT",
    NYSEARCA = "NYSEARCA",
    OTC = "OTC",
    BATS = "BATS",
    INDEX = "INDEX",
}

export enum ContextStatus {
    UNINITIALIZED = 0,
    LOADING,
    FAILED,
    LOADED,
    UPDATING,
}

export enum DiscoverPanelStatus {
    LOADING = 0,
    IDLE,
    ACTION_SENT,
    RESPONSE_PENDING,
    RESPONDING,
}

export enum ActionLaunchStatus {
    LAUNCHED = 0,
    BETA,
    SUPERADMIN,
    DEPRECATED,
}

/* ********* */

export interface OnboardableEntity {
    id: UUID;
    created_at: string;
    deactivated_at: string | null;
    onboarded_at: string | null;
}

export interface PageInfo {
    has_next_page: boolean;
    end_cursor?: string | null;
}

export interface CursorPage<T> {
    items: T[];
    count: number;
    page_info: PageInfo;
}

export interface DefaultPage<T> {
    items: T[];
    page: number;
    pages: number;
    size: number;
    total: number;
}
export type Page<T> = CursorPage<T> | DefaultPage<T>;

export type Metric = {
    value: number;
    percentile: number;
};

export type DashboardMetric = {
    value: number | null;
    time_series: [string, number][] | null;
};

export type DashboardMetricsV2 = {
    values: [string, number][]; // [metric_name, value][]
    ts_keys: string[]; // metric_name[]
    ts: [string, [string, number][]][]; // [metric_name, [timestamp, value][]][]
};

export type PipelineStepMetrics = {
    complete_ms: number;
    failed_ms: number;
    ms: Metric;
};

export type PipelineMetrics = {
    queue: Metric;
    pipeline: PipelineStepMetrics;
    total: PipelineStepMetrics;
};

export type UploadFileOk = {
    upload_id: UUID;
    status: Extract<
        FileStatus,
        FileStatus.uploaded | FileStatus.processing | FileStatus.ready
    >;
    name: string;
    document_info: DocumentInfo;
};

export type UploadFile =
    | {
          upload_id: UUID;
          status: Extract<
              FileStatus,
              | FileStatus.initial
              | FileStatus.fetching_info
              | FileStatus.uploading
          >;
          name: string;
      }
    | {
          upload_id: UUID;
          status: Extract<FileStatus, FileStatus.failed | FileStatus.rejected>;
          name: string;
          error?: string;
      }
    | UploadFileOk;

export type Sorting = {
    sorted_by: string;
    direction: SortingDirection;
};

// https://docs.stripe.com/invoicing/overview#invoice-statuses
export enum InvoiceStatus {
    draft = "draft",
    open = "open",
    paid = "paid",
    uncollectible = "uncollectible",
    void = "void",
}

export enum QuartrDocumentType {
    slides = 5,
    report = 6,
    quarterly_report = 7,
    pdf_transcript = 9,
    earnings_release_8k = 10,
    annual_report_10k = 11,
    annual_report_other = 12,
    annual_report_20f = 13,
    earnings_release_6k = 14,
    transcript = 15,
    interim_report = 17,
    annual_report_40f = 18,
    press_release = 19,
    earnings_release_other = 20,
}

export enum QuartrEventType {
    guidance = 8,
    partnership = 10,
    collaboration = 11,
    fireside_chat = 12,
    clevel_sitdown = 13,
    ceo_sitdown = 14,
    department_sitdown = 16,
    ma_announcement = 17,
    capital_raise = 18,
    fda_announcement = 19,
    status_update = 21,
    product_launch = 23,
    service_launch = 24,
    q1 = 26,
    q2 = 27,
    q3 = 28,
    q4 = 29,
    slides = 30,
    investor_day = 31,
    business_combination = 32,
    analyst_day = 33,
    conference = 237,
    investor_update = 239,
    esg_update = 240,
    investor_presentation = 275,
    annual_report = 277,
    esg_report = 278,
    corporate_presentation = 279,
    company_presentation = 283,
}

// These event types will only have slides associated with them
export const QUARTR_SLIDES_EVENT_TYPES = new Set([
    QuartrEventType.q1,
    QuartrEventType.q2,
    QuartrEventType.q3,
    QuartrEventType.q4,
]);

export interface ActionConfigBase {
    launchStatus: ActionLaunchStatus;
    label: string;
    description?: string;
}

export interface ActionConfig extends ActionConfigBase {
    type: ActionType;
}

export type Citation = {
    display_text: string;
    document_info: DocumentInfo;
};

export enum CitationType {
    invalid = "invalid",
    citation = "citation",
    finding = "finding",
}

interface CitationDataMap {
    [CitationType.invalid]: object;
    [CitationType.citation]: { citation_id: UUID };
    [CitationType.finding]: { finding_id: UUID };
}

export type CitationData = {
    [K in keyof typeof CitationType]: {
        type: (typeof CitationType)[K];
    } & CitationDataMap[(typeof CitationType)[K]];
}[keyof typeof CitationType];

export type DocumentWithAsyncFindings = {
    info: DocumentInfo;
    finding_groups: Map<string, AsyncFindingGroup>;
};

export type ConfigurationMeta = Readonly<{
    env: string;
    featureFlags: FeatureFlags;
    fileUploadBoxEnabled: boolean;
    fileUploadDropBoxEnabled: boolean;
    fileUploadGoogleEnabled: boolean;
    fileUploadOneDriveEnabled: boolean;
    fullstoryOrgId: string;
    googleAnalyticsId?: string;
    googleMapsApiKey: string;
    googleFilePickerApiKey: string;
    googleFilePickerAppId: string;
    googleFilePickerClientId: string;
    isDev: boolean;
    launchDarklyClientId: string;
    sentryDsn: string;
    sentryEnv: string;
    sha: string;
    stripePublicKey: string;
    auth0ClientId: string;
    auth0Domain: string;
    loginRealm: string;
}>;
