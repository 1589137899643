import { createFileRoute } from "@tanstack/react-router";

import { api } from "@/api/rest";
import { ProjectRoot } from "@/app/project/root";

export const Route = createFileRoute("/_app/_v3/project/$projectID/_project/")({
    async loader({ params }) {
        return await api.fetch_project(params.projectID);
    },
    component: RouteComponent,
});

function RouteComponent() {
    const project = Route.useLoaderData();
    return <ProjectRoot project={project} />;
}
