import { createFileRoute } from "@tanstack/react-router";

import { PageSkeleton } from "@/app/page-skeleton";

export const Route = createFileRoute("/_app/page")({
    component: RouteComponent,
});

function RouteComponent() {
    return (
        <PageSkeleton>
            <main className="bg-logo-clipped bg-55 grow animate-pulse bg-left-top bg-no-repeat" />
        </PageSkeleton>
    );
}
