import { createFileRoute, redirect } from "@tanstack/react-router";

// redirects to dashboard
export const Route = createFileRoute("/")({
    beforeLoad: async () => {
        throw redirect({
            to: "/dashboard",
        });
    },
});
