import { Loader2 } from "lucide-react";
import { ComponentPropsWithRef, ReactNode, SyntheticEvent } from "react";

import { Button, ButtonProps } from "@/components/ui/button";
import { UseAsyncState } from "@/hooks/use-async-state";
import { cn } from "@/lib/utils";

type AsyncProps = { loading?: boolean } & ButtonProps;
type ActionProps = { action: UseAsyncState<[SyntheticEvent], unknown> } & Omit<
    ButtonProps,
    "onClick"
>;

export type AsyncButtonProps = (AsyncProps | ActionProps) & {
    loader?: ReactNode;
} & ComponentPropsWithRef<"button">;

const transformProps = (props: AsyncButtonProps): AsyncProps => {
    if ("action" in props) {
        const { action, ...rest } = props;
        return {
            ...rest,
            loading: action.isSubmitting,
            onClick: action.submit,
        };
    }
    return props;
};

export const AsyncButton = ({ ref, loader, ...props }: AsyncButtonProps) => {
    const { children, className, loading, ...buttonProps } =
        transformProps(props);

    return (
        <Button
            ref={ref}
            {...buttonProps}
            className={cn("relative", className)}
        >
            <span
                className={cn(
                    "absolute transition-opacity",
                    loading ? "opacity-100 delay-75" : "opacity-0",
                )}
            >
                {loader ?? <Loader2 className="size-4 animate-spin" />}
            </span>
            <span
                className={cn(
                    "flex items-center gap-2 transition-opacity",
                    loading ? "opacity-0" : "opacity-100 delay-75",
                )}
            >
                {children}
            </span>
        </Button>
    );
};
