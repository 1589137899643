import { ZapIcon } from "lucide-react";
import { createPortal } from "react-dom";

import { useAppOutdated } from "@/hooks/use-app-outdated";

export const OutdatedBanner = () => {
    const isOutdated = useAppOutdated();
    if (!isOutdated) return null;
    return createPortal(
        <div className="pointer-events-auto fixed top-0 left-1/2 z-[9999] flex -translate-x-1/2 items-center gap-4 rounded-b-md bg-yellow-100 px-10 py-2 text-sm text-yellow-700 dark:bg-yellow-800 dark:text-yellow-400">
            <ZapIcon className="size-4 shrink-0 fill-current" />
            <p>
                Brightwave just got better. To get the latest updates refresh
                the page or{" "}
                <a
                    href="#"
                    onClick={() => window.location.reload()}
                    className="underline underline-offset-4"
                >
                    click here
                </a>
                .
            </p>
        </div>,
        document.body,
    );
};
