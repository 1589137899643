import { createFileRoute } from "@tanstack/react-router";

import { Login } from "@/app/auth/login";
import { maybeString } from "@/utils/string-helpers";

type SearchParams = {
    return_url?: string;
    error?: string;
    connection?: string;
};

const parseError = (error: unknown): string | undefined => {
    const str = maybeString(error);
    return str ? str.replace("+", " ") : undefined;
};

export const Route = createFileRoute("/_auth/login")({
    validateSearch: (search: Record<string, unknown>): SearchParams => ({
        return_url: maybeString(search.return_url),
        error: parseError(search.error),
        connection: maybeString(search.connection),
    }),
    component: RouteComponent,
});

function RouteComponent() {
    const params = Route.useSearch();
    return <Login {...params} />;
}
