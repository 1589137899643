import { PropsWithChildren } from "react";
import useSWR from "swr";

import { HttpError } from "@/api/rest";
import { ConfigurationMeta } from "@/api/types";
import { ConfigContext } from "@/context/config-context";
import { isNull } from "@/utils/fn";

// @ts-expect-error __BW_CONFIG__ is a global variable and eslint doesn't like it
const bootstrapConfig: ConfigurationMeta | undefined = window["__BW_CONFIG__"];

const fetchConfig = async (): Promise<ConfigurationMeta> => {
    const response = await fetch("/api/frontend-settings");
    if (!response.ok) {
        throw new HttpError("Failed to load config", response.status);
    }
    return await response.json();
};

function BlockingContextProvider(props: PropsWithChildren) {
    const { data } = useSWR("app-config", fetchConfig, {
        suspense: true,
    });
    return <ConfigContext value={data} {...props} />;
}

function NonBlockingContextProvider({
    config,
    ...props
}: PropsWithChildren<{ config: ConfigurationMeta }>) {
    const { data } = useSWR("app-config", fetchConfig);
    return <ConfigContext value={data ?? config} {...props} />;
}

export function ConfigProvider(props: PropsWithChildren) {
    return isNull(bootstrapConfig) ? (
        <BlockingContextProvider {...props} />
    ) : (
        <NonBlockingContextProvider config={bootstrapConfig} {...props} />
    );
}
