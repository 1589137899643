import { createFileRoute, notFound } from "@tanstack/react-router";
import { validate } from "uuid";

import { api } from "@/api/rest";
import { DocumentNotAvailable } from "@/app/document/not-available";
import { DocumentPage } from "@/app/document/page";
import { DocumentCollectionType, ProjectComponentType } from "@/openapi-schema";
import { first } from "@/utils/collection";

export const Route = createFileRoute(
    "/_app/_v3/project/$projectID/_project/doc/$documentID",
)({
    beforeLoad: ({ params }) => {
        if (!validate(params.documentID)) {
            throw notFound();
        }
    },
    async loader({ params }) {
        const [project, component] = await Promise.all([
            api.fetch_project(params.projectID),
            api
                .fetch_project_components(
                    params.projectID,
                    ProjectComponentType.document_collection,
                )
                .then(first),
        ]);

        if (component?.type !== DocumentCollectionType.document_collection) {
            throw notFound();
        }

        const { documents } = await api.fetch_project_component(
            project.id,
            component.id,
        );

        const document = documents.find(
            ({ info }) => info.id === params.documentID,
        );
        return { document: document?.info, project };
    },
    component: RouteComponent,
});

function RouteComponent() {
    const { project, document } = Route.useLoaderData();
    if (!document) {
        // document no longer available
        return <DocumentNotAvailable project={project} />;
    }
    return <DocumentPage documentInfo={document} project={project} />;
}
