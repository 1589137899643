import { createFileRoute, notFound } from "@tanstack/react-router";

import { DevSettings } from "@/app/settings/dev";
import { isUserSuperAdmin } from "@/utils/user";

export const Route = createFileRoute("/_app/settings/dev")({
    beforeLoad: async ({ context }) => {
        if (!context.auth.user || !isUserSuperAdmin(context.auth.user)) {
            throw notFound();
        }
    },
    component: DevSettings,
});
