import { createFileRoute, redirect } from "@tanstack/react-router";

import { AppLayout } from "@/app/layout";
import { WebsocketProvider } from "@/provider/websocket";
import { shouldSeePaywall } from "@/utils/user";

export const Route = createFileRoute("/_app")({
    beforeLoad: ({ context }) => {
        // redirect unauthenticated users to login page
        if (!context.auth.isAuthenticated) {
            throw redirect({
                to: "/login",
                search: {
                    return_url: window.location.pathname,
                },
            });
        }
        // redirect to paywall
        if (shouldSeePaywall(context.auth.user)) {
            throw redirect({
                to: "/paywall",
            });
        }
    },
    component: () => (
        <WebsocketProvider>
            <AppLayout />
        </WebsocketProvider>
    ),
});
