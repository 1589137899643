import { OnboardingStatusEnum } from "@/api/types";
import {
    SchemaPermissionsUser,
    SchemaUser,
    CustomerStatus,
    AccountType,
    UserType,
    SchemaAccount,
} from "@/openapi-schema";
import { isEmptyOrNull, notEmptyOrNull } from "@/utils/string-helpers";

export const getOnboardingStatus = (
    entity: SchemaAccount | SchemaUser | SchemaPermissionsUser,
): OnboardingStatusEnum => {
    if (
        "account_deactivated_at" in entity &&
        entity.account_deactivated_at != null
    )
        return OnboardingStatusEnum.deactivated;
    if (entity.deactivated_at != null) return OnboardingStatusEnum.deactivated;
    if (entity.onboarded_at != null) return OnboardingStatusEnum.onboarded;
    return OnboardingStatusEnum.created;
};

export const isUserTypeAdmin = (user: SchemaPermissionsUser | null): boolean =>
    user != null && user.user_type === UserType.admin;

export const isUserSuperAdmin = (user: SchemaPermissionsUser): boolean =>
    user.account_type === AccountType.admin;

export const hasBetaAccess = (user: SchemaPermissionsUser | null): boolean =>
    user != null && user?.beta_access === true;

export const getLastFirstName = ({
    first_name,
    last_name,
}: SchemaPermissionsUser | SchemaUser): string =>
    [last_name, first_name]
        .filter(notEmptyOrNull)
        .map((s) => s.trim())
        .filter(notEmptyOrNull)
        .join(", ");

export const getFirstLastName = ({
    first_name,
    last_name,
}: SchemaPermissionsUser | SchemaUser): string =>
    [first_name, last_name]
        .filter(notEmptyOrNull)
        .map((s) => s.trim())
        .filter(notEmptyOrNull)
        .join(" ");

export const getNameOrEmail = (
    user: SchemaPermissionsUser | SchemaUser,
): string =>
    isEmptyOrNull(user.first_name) || isEmptyOrNull(user.last_name)
        ? user.username
        : getFirstLastName(user);

export const deactivatedAt = (
    entity: SchemaAccount | SchemaPermissionsUser | SchemaUser,
): string | null => {
    if (
        "account_deactivated_at" in entity &&
        entity.account_deactivated_at != null
    ) {
        return entity.account_deactivated_at;
    }
    return entity.deactivated_at;
};

export const canSeeBillingSettings = (
    user: SchemaPermissionsUser | null,
): user is SchemaPermissionsUser => {
    if (user == null || !user.paid) return false;
    if (user.account_type === AccountType.individual) return true;
    if (user.account_type === AccountType.organization)
        return user.user_type === UserType.admin;
    return false;
};

export const canSeeManageUsersSettings = (
    user: SchemaUser | SchemaPermissionsUser | null,
): boolean =>
    user != null &&
    user.account_type === AccountType.organization &&
    user.user_type === UserType.admin;

export const getGreeting = (
    user: SchemaUser | SchemaPermissionsUser | null,
): string =>
    user && notEmptyOrNull(user.first_name)
        ? `Hello ${user.first_name}.`
        : "Hello.";

export const isLoggedInAs = (user: SchemaPermissionsUser | null): boolean =>
    user?.token_details.parent_token_id != null;

export const shouldSeePaywall = (user: SchemaPermissionsUser) => {
    if (user.paid) {
        switch (user.status) {
            case CustomerStatus.subscription_confirmation_needed:
            case CustomerStatus.subscription_pending:
            case CustomerStatus.trial_pending:
            case CustomerStatus.billing_details_required:
            case CustomerStatus.payment_required:
            case CustomerStatus.lapsed:
                return true;

            case CustomerStatus.active:
            case CustomerStatus.trialing:
                return false;
            default:
                return user.status satisfies never;
        }
    }
};

export const isUserOfPaywalledOrgAccount = (
    user: SchemaPermissionsUser,
): boolean => {
    if (
        user.paid &&
        user.account_type === AccountType.organization &&
        user.user_type !== UserType.admin
    ) {
        switch (user.status) {
            case CustomerStatus.subscription_confirmation_needed:
            case CustomerStatus.payment_required:
            case CustomerStatus.trial_pending:
            case CustomerStatus.billing_details_required:
                return true;
            case CustomerStatus.lapsed:
            case CustomerStatus.subscription_pending:
            case CustomerStatus.active:
            case CustomerStatus.trialing:
                return false;
            default:
                return user.status satisfies never;
        }
    }
    return false;
};

export const showTrialBanner = (user: SchemaPermissionsUser | null) =>
    user &&
    user.status === CustomerStatus.trialing &&
    canSeeBillingSettings(user);
