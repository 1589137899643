import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { Suspense } from "react";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Button } from "@/components/ui/button";
import { useUser } from "@/provider/auth";
import { getUserName } from "@/utils/string-helpers";
import { shouldSeePaywall } from "@/utils/user";

export const Route = createFileRoute("/_paywall")({
    beforeLoad({ context }) {
        if (!context.auth.isAuthenticated) {
            throw redirect({ to: "/login" });
        }
        if (!shouldSeePaywall(context.auth.user)) {
            throw redirect({ to: "/" });
        }
    },
    component: PaywallLayout,
});

function PaywallLayout() {
    const user = useUser();
    return (
        <>
            <header className="border-border flex h-16 w-full items-center justify-between border-b pr-2 pl-4">
                <BrightwaveLogo />
                <div className="flex items-center justify-end gap-4 text-sm">
                    <p>{getUserName(user)}</p>
                    <Button variant="ghost-destructive" size="sm" asChild>
                        <a href="/api/auth/logout">Logout</a>
                    </Button>
                </div>
            </header>
            <main className="mx-auto max-w-(--breakpoint-sm) space-y-10 px-8 py-28">
                <Suspense
                    fallback={
                        <div className="felx justify-center p-20">
                            <Loader2Icon className="size-4 animate-spin" />
                        </div>
                    }
                >
                    <Outlet />
                </Suspense>
            </main>
        </>
    );
}
