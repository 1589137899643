import { createFileRoute, notFound } from "@tanstack/react-router";
import { PropsWithChildren } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Suspense } from "react";
import useSWRImmutable from "swr/immutable";
import { v4 as uuidv4 } from "uuid";

import { api } from "@/api/rest";
import { SubscriptionContainer } from "@/app/paywall/subscription";
import { BillingInformation } from "@/app/settings/billing/billing-information";
import { Invoices } from "@/app/settings/billing/invoices";
import { PaymentMethods } from "@/app/settings/billing/payment-methods";
import { SubscriptionDetails } from "@/components/billing/subscription-details";
import { PageHeader, PageHeaderTitle } from "@/components/layout/page-header";
import { BillingSettingsContext } from "@/context/billing-context";
import { useUser } from "@/provider/auth";
import { canSeeBillingSettings } from "@/utils/user";

export const Route = createFileRoute("/_app/settings/billing")({
    beforeLoad({ context }) {
        if (!canSeeBillingSettings(context.auth.user)) {
            throw notFound();
        }
    },
    component: RouteComponent,
});

function RouteComponent() {
    const user = useUser();
    const [fetchKey, setFetchKey] = useState<string | null>(null);
    const setRandomFetchKey = () => setFetchKey(uuidv4());
    useEffect(setRandomFetchKey, [setFetchKey]);
    return (
        <BillingSettingsContextProvider>
            <PageHeader>
                <PageHeaderTitle htmlTitle="Billing">Billing</PageHeaderTitle>
            </PageHeader>
            <div className="overflow-scroll">
                <div className="col-span-2 container max-w-4xl space-y-10 py-10 lg:col-span-3">
                    <div className="space-y-4">
                        <h1 className="text-headline text-xl font-medium">
                            Current Plan
                        </h1>
                        {fetchKey && (
                            <SubscriptionContainer fetchKey={fetchKey}>
                                {({ subscription }) => (
                                    <SubscriptionDetails
                                        user={user}
                                        subscription={subscription}
                                    />
                                )}
                            </SubscriptionContainer>
                        )}
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-headline text-xl font-medium">
                            Billing Information
                        </h1>
                        <Suspense fallback={<div>Loading...</div>}>
                            <BillingInformation />
                        </Suspense>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-headline text-xl font-medium">
                            Payment Methods
                        </h1>
                        <Suspense fallback={<div>Loading...</div>}>
                            <PaymentMethods />
                        </Suspense>
                    </div>
                    <div className="space-y-4">
                        <h1 className="text-headline text-xl font-medium">
                            Invoices
                        </h1>
                        <Suspense fallback={<div>Loading...</div>}>
                            <Invoices onPaymentSuccess={setRandomFetchKey} />
                        </Suspense>
                    </div>
                </div>
            </div>
        </BillingSettingsContextProvider>
    );
}

function BillingSettingsContextProvider(props: PropsWithChildren) {
    const { data, mutate: mutateDefaultPaymentMethod } = useSWRImmutable(
        "default_payment_method_provider",
        api.fetch_default_payment_method,
    );
    return (
        <BillingSettingsContext
            value={{
                defaultPaymentMethod: data ?? null,
                mutateDefaultPaymentMethod,
            }}
            {...props}
        />
    );
}
