import { createFileRoute, notFound } from "@tanstack/react-router";
import { validate } from "uuid";

import { api } from "@/api/rest";
import { GridViewProps, GridViewRoot } from "@/app/grid-view/root";
import { GridViewContentSkeleton } from "@/components/analyze/skeleton";
import {
    DocumentCollectionType,
    ProjectComponentType,
    SchemaDocumentCollection,
} from "@/openapi-schema";
import { first } from "@/utils/collection";

export const Route = createFileRoute("/_app/analyze/$project_id_")({
    beforeLoad: ({ params }) => {
        if (!validate(params.project_id)) {
            throw notFound();
        }
    },
    loader: async ({ params }): Promise<GridViewProps> => {
        const documentCollection = first(
            await api.fetch_project_components(
                params.project_id,
                ProjectComponentType.document_collection,
            ),
        ) as SchemaDocumentCollection | null;

        if (!documentCollection) {
            throw notFound();
        }

        const [{ info, blueprint, documents }, clientState, availableColumns] =
            await Promise.all([
                await api.fetch_project_component(
                    documentCollection.project_id,
                    documentCollection.id,
                ),
                await api.fetch_grid_view_state(documentCollection.id),
                await api.fetch_system_probes(),
            ]);

        if (info.type !== DocumentCollectionType.document_collection) {
            throw notFound();
        }

        // TODO: explore non-blocking prefetching
        const documentFindingGroups = await api.fetch_document_finding_groups(
            documentCollection.id,
            documents.map(({ info }) => info.id),
        );

        return {
            info,
            blueprint,
            documents: documents.map(({ info }) => info),
            clientState,
            availableColumns,
            documentFindingGroups,
        };
    },
    component: RouteComponent,
    pendingComponent: GridViewContentSkeleton,
});

function RouteComponent() {
    const data = Route.useLoaderData();
    return <GridViewRoot {...data} />;
}
