import { Link } from "@tanstack/react-router";
import { DatabaseZapIcon, HomeIcon, RefreshCwIcon } from "lucide-react";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Copyright } from "@/components/copyright";
import { Debugger } from "@/components/debug/debugger";
import { ErrorAlert } from "@/components/error-alert";
import { Headline } from "@/components/headline";
import { Button } from "@/components/ui/button";

type Props = {
    error?: Error;
    info?: {
        componentStack: string;
    };
    reset?: () => void;
};
export function PageError(props: Props) {
    const recover = () => {
        localStorage.clear();
        sessionStorage.clear();
        props.reset?.();
    };
    return (
        <div className="bg-logo-clipped bg-55 flex min-h-screen flex-col items-center justify-between bg-left-top bg-no-repeat pt-40 pb-4">
            <BrightwaveLogo className="h-auto w-52" />
            <div className="grow space-y-8 py-20 text-center">
                <Headline level={2}>We're sorry, something went wrong</Headline>
                <p>
                    Try to refresh, clear your cache, and contact{" "}
                    <a
                        href="mailto:support@brightwave.io"
                        className="underline underline-offset-4"
                    >
                        support@brightwave.io
                    </a>{" "}
                    if the error persists.
                </p>
                <div className="flex items-center justify-center gap-4">
                    <Button asChild>
                        <Link to="/">
                            <HomeIcon />
                            Go Home
                        </Link>
                    </Button>
                    <Button onClick={props.reset}>
                        <RefreshCwIcon />
                        Refresh Page
                    </Button>
                </div>
                <Button variant="destructive" onClick={recover}>
                    <DatabaseZapIcon />
                    Clear Cache and Refresh Page
                </Button>
                {props.error && (
                    <Debugger showOnDevEnv>
                        <ErrorAlert className="text-left" error={props.error} />
                    </Debugger>
                )}
            </div>
            <Copyright />
        </div>
    );
}
