import { createFileRoute } from "@tanstack/react-router";

import { AdminManageAccountContainer } from "@/container/admin/manage-account";
import { AdminManageAccountContextProvider } from "@/context/admin-manage-account-context-provider";

export const Route = createFileRoute(
    "/_app/admin/_admin/accounts/_accounts/$id",
)({
    component: RouteComponent,
});

function RouteComponent() {
    const { id } = Route.useParams();

    return (
        <AdminManageAccountContextProvider accountID={id}>
            <AdminManageAccountContainer accountID={id} />
        </AdminManageAccountContextProvider>
    );
}
