import { createFileRoute, notFound } from "@tanstack/react-router";
import { validate } from "uuid";

import { api } from "@/api/rest";
import { DocumentPage } from "@/app/document/page";
import { DocumentCollectionType, ProjectComponentType } from "@/openapi-schema";
import { first } from "@/utils/collection";

export const Route = createFileRoute("/_app/analyze/$project_id/$document_id")({
    beforeLoad: ({ params }) => {
        if (!validate(params.document_id)) {
            throw notFound();
        }
    },
    async loader({ params }) {
        const [project, component] = await Promise.all([
            api.fetch_project(params.project_id),
            api
                .fetch_project_components(
                    params.project_id,
                    ProjectComponentType.document_collection,
                )
                .then(first),
        ]);

        if (component?.type !== DocumentCollectionType.document_collection) {
            throw notFound();
        }

        const { documents } = await api.fetch_project_component(
            project.id,
            component.id,
        );

        const document = documents.find(
            ({ info }) => info.id === params.document_id,
        );
        if (!document) {
            throw notFound();
        }
        return { document: document.info, project };
    },
    component: RouteComponent,
});

function RouteComponent() {
    const { project, document } = Route.useLoaderData();
    return <DocumentPage project={project} documentInfo={document} />;
}
