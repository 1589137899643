import { createFileRoute, notFound } from "@tanstack/react-router";
import Box from "@uppy/box";
import Uppy from "@uppy/core";
import Dropbox from "@uppy/dropbox";
import GoogleDrivePicker from "@uppy/google-drive-picker";
import OneDrive from "@uppy/onedrive";
import { Dashboard } from "@uppy/react";
import XHR from "@uppy/xhr-upload";

import { useConfig } from "@/hooks/use-config";
import { isUserSuperAdmin } from "@/utils/user";

import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";

export const Route = createFileRoute("/_app/filebrowse")({
    component: RouteComponent,
    beforeLoad({ context }) {
        if (
            !context.auth.isAuthenticated ||
            !isUserSuperAdmin(context.auth.user)
        ) {
            throw notFound();
        }
    },
});

function RouteComponent() {
    const {
        fileUploadBoxEnabled,
        fileUploadDropBoxEnabled,
        fileUploadGoogleEnabled,
        fileUploadOneDriveEnabled,
        googleFilePickerApiKey,
        googleFilePickerAppId,
        googleFilePickerClientId,
    } = useConfig();
    const uppy = new Uppy().use(XHR, {
        endpoint: `${window.location.origin}/document/upload/files`,
        bundle: false, // Should always be false for multipart uploads
        formData: true,
        fieldName: "file", // Should always be "file"
        headers: { test: "test" }, // If additional context is needed, these headers will be sent to the /upload endpoint (on API)
    });

    if (fileUploadBoxEnabled) {
        uppy.use(Box, {
            companionUrl: `${window.location.origin}/uppy`,
        });
    }
    if (fileUploadDropBoxEnabled) {
        uppy.use(Dropbox, {
            companionUrl: `${window.location.origin}/uppy`,
        });
    }
    if (fileUploadGoogleEnabled) {
        uppy.use(GoogleDrivePicker, {
            companionUrl: `${window.location.origin}/uppy`,
            clientId: googleFilePickerClientId,
            apiKey: googleFilePickerApiKey,
            appId: googleFilePickerAppId,
        });
    }
    if (fileUploadOneDriveEnabled) {
        uppy.use(OneDrive, {
            companionUrl: `${window.location.origin}/uppy`,
        });
    }

    uppy.on("upload-success", (file, response) => {
        console.log("Upload successful:", file, response);
    });

    return <Dashboard uppy={uppy} proudlyDisplayPoweredByUppy={false} />;
}
