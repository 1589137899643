import { Link } from "@tanstack/react-router";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Copyright } from "@/components/copyright";

export function PageNotFound() {
    return (
        <div className="bg-logo-clipped bg-55 flex min-h-screen flex-col items-center justify-between bg-left-top bg-no-repeat pt-40 pb-4">
            <BrightwaveLogo className="h-auto w-52" />
            <div className="grow space-y-8 py-20 text-center">
                <h1 className="font-headline text-4xl font-black text-red-500 uppercase">
                    404
                    <span className="font-headline text-foreground block text-3xl font-black uppercase">
                        Not found
                    </span>
                </h1>
                <p>The page you requested does not exist.</p>
                <Link to="/" className="underline underline-offset-4">
                    Go back home
                </Link>
            </div>
            <Copyright />
        </div>
    );
}
