import { createFileRoute, notFound, Outlet } from "@tanstack/react-router";

import { PageSkeleton } from "@/app/page-skeleton";
import { FeatureFlagBool } from "@/conf/feature-flags";
import { isUserSuperAdmin } from "@/utils/user";

export const Route = createFileRoute("/_app/_v3")({
    beforeLoad({ context }) {
        if (
            !context.auth.isAuthenticated ||
            (!context.config.featureFlags[FeatureFlagBool.FORCE_V3] &&
                !isUserSuperAdmin(context.auth.user))
        ) {
            throw notFound();
        }
    },
    component: Outlet,
    pendingComponent: () => (
        <PageSkeleton>
            <main className="bg-logo-clipped bg-55 grow animate-pulse bg-left-top bg-no-repeat" />
        </PageSkeleton>
    ),
});
