import { FetchResponse } from "openapi-fetch";
import { v4 as uuidv4 } from "uuid";

import { client } from "@/api/client";
import { HttpStatusCode } from "@/api/http-status-codes";
import { Sorting, UUID } from "@/api/types";
import { base_api_url } from "@/api/utils";
import {
    AccountType,
    InviteCodeStatus,
    ProjectComponentType,
    SchemaAccountInviteRequest,
    SchemaAccountSettingsInput,
    SchemaBillingDetailsSubmission,
    SchemaBlueprintDisplayInfo,
    SchemaBodyAddDocumentsToDocumentCollectionEndpointDocumentCollectionDocumentCollectionIdDocumentPost,
    SchemaBodyAddSynthesisToDocumentCollectionDocumentCollectionDocumentCollectionIdSynthesisPost,
    SchemaBodyCreateProjectProjectPost,
    SchemaConnectionLookupRequestSchema,
    SchemaCreateAnalysisProbeSchema,
    SchemaCreateReportForDocumentCollectionRequest,
    SchemaCreateShareRequest,
    SchemaCreateSynthesisProbeSchema,
    SchemaFeedback,
    SchemaGridViewClientState,
    SchemaInviteAcceptance,
    SchemaInviteAcceptanceBase,
    SchemaProbeInputSchema,
    SchemaSubscriptionInfo,
    SchemaUpdateProjectMetadataRequest,
    SchemaUpdateShareRequest,
    SchemaUserInfo,
    SchemaUserInviteDetails,
} from "@/openapi-schema";
import { balancedChunks } from "@/utils/collection";
import * as objMap from "@/utils/object-map";

enum HTTPMethod {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    PATCH = "PATCH",
    DELETE = "DELETE",
    OPTIONS = "OPTIONS",
}

export type CursorPaginationParams = {
    size: number;
    cursor?: string | null;
};

type TUrlParams<T> = Record<string, T> | [string, T][];

type RequestOptions = {
    body: unknown;
    omitContentType?: boolean;
    params: TUrlParams<string> | null;
    suppressErrors: boolean; // don't throw
    silent: boolean; // don't invoke callbacks
};

export class HttpError extends Error {
    public name: string = "HttpError";
    public status: HttpStatusCode | undefined;

    public constructor(message: string, status?: HttpStatusCode) {
        super(message);
        this.status = status;
    }
}

function getBody(body: unknown): string | FormData | null {
    if (body == null) {
        return null;
    }
    if (typeof body === "string" || body instanceof FormData) {
        return body;
    }
    return JSON.stringify(body);
}

function getURL(
    endpoint: string,
    params: TUrlParams<string> | null | undefined,
) {
    if (params == null) {
        return `${base_api_url}/${endpoint}`;
    }
    return `${base_api_url}/${endpoint}?${new URLSearchParams(params)}`;
}

const notAuthenticatedCallbackMap = new Map<string, () => void>();

const defaultHeaders = {
    Accept: "application/json",
};

async function sendRequest(
    method: HTTPMethod,
    endpoint: string,
    options?: Partial<RequestOptions> & { authRequired?: boolean },
): Promise<Response> {
    const headers: Record<string, string> = options?.omitContentType
        ? defaultHeaders
        : { ...defaultHeaders, "Content-Type": "application/json" };

    const res = await fetch(getURL(endpoint, options?.params), {
        method: method,
        headers: headers,
        body: getBody(options?.body),
    });

    if (options?.silent !== true) {
        if (res.status === HttpStatusCode.Unauthorized) {
            for (const cb of notAuthenticatedCallbackMap.values()) {
                cb();
            }
        }
    }

    if (options?.suppressErrors !== true && !res.ok) {
        const json = await res.json();
        throw new HttpError(json.detail, res.status);
    }

    return res;
}

const unwrapRequest = async <
    T extends Record<string | number, unknown>,
    Options,
    Media extends `${string}/${string}`,
>(
    p: Promise<FetchResponse<T, Options, Media>>,
) => {
    try {
        const result = await p;
        if (!result.response.ok || result.error != undefined) {
            const error = result.error as { detail: string } | null;
            throw new HttpError(
                error?.detail ?? result.response.statusText,
                result.response.status,
            );
        }
        return result.data!;
    } catch (error) {
        if (
            error instanceof HttpError &&
            error.status === HttpStatusCode.Unauthorized
        ) {
            for (const cb of notAuthenticatedCallbackMap.values()) {
                cb();
            }
        }
        throw error;
    }
};

export const api = {
    onNotAuthenticated(cb: () => void) {
        const id = uuidv4();
        notAuthenticatedCallbackMap.set(id, cb);
        return () => {
            notAuthenticatedCallbackMap.delete(id);
        };
    },

    async get(
        endpoint: string,
        options?: Partial<Omit<RequestOptions, "body">>,
    ): Promise<Response> {
        return await sendRequest(HTTPMethod.GET, endpoint, options);
    },

    async check_invite_code(invite_info: SchemaInviteAcceptanceBase) {
        const res = (await unwrapRequest(
            client.POST("/onboard/check-status", { body: invite_info }),
        )) as { status: InviteCodeStatus };
        return res.status;
    },

    async onboard_accept(invite_info: SchemaInviteAcceptance) {
        return await unwrapRequest(
            client.POST("/onboard/accept", { body: invite_info }),
        );
    },

    async connection(body: SchemaConnectionLookupRequestSchema) {
        return unwrapRequest(client.POST("/auth/connection", { body }));
    },

    async update_password() {
        await unwrapRequest(client.POST("/auth/password/change", {}));
        return true;
    },

    async fetch_user_info() {
        // todo silent?
        return await unwrapRequest(client.GET("/user"));
    },

    async fetch_analysis_by_id(finding_group_id: UUID) {
        return await unwrapRequest(
            client.GET("/analysis/{finding_group_id}", {
                params: { path: { finding_group_id } },
            }),
        );
    },

    async update_user_info(user_info: SchemaUserInfo) {
        return await unwrapRequest(
            client.POST("/user/info", { body: user_info }),
        );
    },

    async admin_fetch_account_list(
        sorting: Sorting | null,
        active: boolean | null,
        account_types: AccountType[] | null,
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET("/admin/account/list", {
                params: {
                    query: {
                        ...pagination,
                        ...sorting,
                        account_types,
                        active,
                    },
                },
            }),
        );
    },

    async admin_fetch_account_info(account_id: UUID) {
        return await unwrapRequest(
            client.GET("/admin/account/{account_id}/info", {
                params: { path: { account_id } },
            }),
        );
    },

    async admin_fetch_account_users(
        account_id: UUID,
        options: Partial<{
            sorting: Sorting | null;
            active: boolean | null;
            pagination?: CursorPaginationParams;
        }> = {},
    ) {
        return await unwrapRequest(
            client.GET("/admin/account/{account_id}/users", {
                params: {
                    path: { account_id },
                    query: {
                        ...options.pagination,
                        ...options.sorting,
                        active: options.active,
                    },
                },
            }),
        );
    },

    async admin_create_account(body: SchemaAccountInviteRequest) {
        return await unwrapRequest(client.POST("/admin/account", { body }));
    },

    async admin_send_user_invite(user_id: UUID) {
        return await unwrapRequest(
            client.POST("/admin/user/{user_id}/send-invite", {
                params: { path: { user_id } },
            }),
        );
    },

    async admin_send_account_invite(account_id: UUID) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/send-invite", {
                params: { path: { account_id } },
            }),
        );
    },

    async admin_deactivate_account(account_id: UUID) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/deactivate", {
                params: { path: { account_id } },
            }),
        );
    },

    async admin_deactivate_user(user_id: UUID) {
        return await unwrapRequest(
            client.POST("/admin/user/{user_id}/deactivate", {
                params: { path: { user_id } },
            }),
        );
    },

    async admin_fetch_user_invite_codes(
        user_id: UUID,
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET("/admin/user/{user_id}/invite-codes", {
                params: { path: { user_id }, query: pagination },
            }),
        );
    },

    async admin_create_user(
        account_id: UUID,
        body: SchemaUserInviteDetails,
        options?: Partial<{ send_invite: boolean }>,
    ) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/users", {
                params: {
                    path: { account_id },
                    query: { send_code: options?.send_invite },
                },
                body,
            }),
        );
    },

    async admin_fetch_account_settings(account_id: UUID) {
        return await unwrapRequest(
            client.GET("/admin/account/{account_id}/settings", {
                params: { path: { account_id } },
            }),
        );
    },

    async admin_update_account_settings(
        account_id: UUID,
        settings: SchemaAccountSettingsInput,
    ) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/settings", {
                params: { path: { account_id } },
                body: settings,
            }),
        );
    },

    async admin_promote_account_to_organization(
        account_id: UUID,
        name: string,
    ) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/convert-to-organization", {
                params: { path: { account_id } },
                body: { name },
            }),
        );
    },

    async admin_fetch_action_panel_messages(
        pagination: CursorPaginationParams,
        exclude_internal: boolean = false,
    ) {
        return await unwrapRequest(
            client.GET("/admin/dashboard/action-panel-message-list", {
                params: {
                    query: {
                        ...pagination,
                        exclude_internal,
                    },
                },
            }),
        );
    },

    async admin_new_default_conversation(project_component_id: UUID) {
        const res = (await unwrapRequest(
            client.POST(
                "/project-component/{project_component_id}/conversation/default",
                {
                    params: { path: { project_component_id } },
                },
            ),
        )) as { conversation_id: UUID };
        return res.conversation_id;
    },

    async admin_fetch_products() {
        return await unwrapRequest(client.GET("/admin/payment/products"));
    },

    async admin_fetch_subscription(account_id: UUID) {
        return await unwrapRequest(
            client.GET("/admin/account/{account_id}/subscription", {
                params: { path: { account_id } },
            }),
        );
    },

    async admin_promoto_to_paid(
        account_id: UUID,
        sub_info: SchemaSubscriptionInfo,
    ) {
        return await unwrapRequest(
            client.POST("/admin/account/{account_id}/convert-to-paid", {
                params: { path: { account_id } },
                body: sub_info,
            }),
        );
    },

    async fetch_account_settings() {
        return await unwrapRequest(client.GET("/account/settings"));
    },

    async fetch_account_users(
        options?: Partial<{
            sorting: Sorting | null;
            active: boolean | null;
            pagination?: CursorPaginationParams;
        }>,
    ) {
        return await unwrapRequest(
            client.GET("/account/users", {
                params: {
                    query: {
                        ...options?.pagination,
                        ...options?.sorting,
                        active: options?.active,
                    },
                },
            }),
        );
    },

    async create_user(
        body: SchemaUserInviteDetails,
        options?: Partial<{ send_invite: boolean }>,
    ) {
        return await unwrapRequest(
            client.POST("/account/users", {
                body,
                params: { query: { send_code: options?.send_invite } },
            }),
        );
    },

    async deactivate_user(user_id: UUID) {
        return await unwrapRequest(
            client.POST("/account/users/{user_id}/deactivate", {
                params: { path: { user_id } },
            }),
        );
    },

    async admin_login_as_user(user_id: UUID) {
        await unwrapRequest(
            client.POST("/admin/user/{user_id}/login-as", {
                params: { path: { user_id } },
            }),
        );
    },

    async admin_switch_back() {
        await unwrapRequest(client.POST("/admin/user/switch-back"));
    },

    async fetch_user_invite_codes(
        user_id: UUID,
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET("/account/users/{user_id}/invite-codes", {
                params: { path: { user_id }, query: { ...pagination } },
            }),
        );
    },

    async send_user_invite(user_id: UUID) {
        return await unwrapRequest(
            client.POST("/account/users/{user_id}/send-invite", {
                params: { path: { user_id } },
            }),
        );
    },

    async send_feedback(message_id: UUID, body: SchemaFeedback) {
        await unwrapRequest(
            client.POST("/feedback/{message_id}", {
                params: { path: { message_id } },
                body,
            }),
        );
    },

    async fetch_converstation_ids(project_component_id: UUID) {
        return await unwrapRequest(
            client.GET(
                "/project-component/{project_component_id}/conversation/list",
                {
                    params: { path: { project_component_id } },
                },
            ),
        );
    },

    async fetch_nux_state() {
        return await unwrapRequest(client.GET("/nux"));
    },

    async mark_nux_seen(nux_id: UUID) {
        return await unwrapRequest(
            client.POST("/nux/{nux_id}/seen", { params: { path: { nux_id } } }),
        );
    },

    async reset_nux(nux_id: UUID | null) {
        return await unwrapRequest(
            client.POST("/nux/reset", { body: { nux_id } }),
        );
    },

    async upload_files(files: { id: UUID; file: File }[]) {
        const formData = new FormData();
        files.forEach(({ file, id }) => {
            formData.append("upload_ids", id);
            formData.append("files", file);
        });
        return await unwrapRequest(
            client.POST("/document/upload/files", {
                // eslint-disable-next-line
                body: formData as any,
            }),
        );
    },

    async delete_file(upload_id: UUID) {
        return await unwrapRequest(
            client.DELETE("/document/upload/{upload_id}", {
                params: { path: { upload_id } },
            }),
        );
    },

    async fetch_conversation_id(project_component_id: UUID) {
        const response = (await unwrapRequest(
            client.GET(
                "/project-component/{project_component_id}/conversation/default",
                {
                    params: { path: { project_component_id } },
                },
            ),
        )) as { conversation_id: UUID; created: boolean };
        return response.conversation_id;
    },

    async fetch_subscription() {
        return await unwrapRequest(client.GET("/account/billing/subscription"));
    },

    async confirm_subscription(
        body: SchemaBillingDetailsSubmission | null = null,
    ) {
        return await unwrapRequest(
            client.POST("/account/billing/subscription/confirm", {
                body,
            }),
        );
    },

    async fetch_stripe_client_secret() {
        return await unwrapRequest(
            client.GET("/account/billing/subscription/payment-intent"),
        );
    },

    async fetch_billing_info() {
        return await unwrapRequest(client.GET("/account/billing/info"));
    },

    async update_billing_info(body: SchemaBillingDetailsSubmission) {
        return await unwrapRequest(
            client.POST("/account/billing/info", {
                body,
            }),
        );
    },

    async fetch_default_payment_method() {
        return await unwrapRequest(
            client.GET("/account/billing/payment-methods/default"),
        );
    },

    async fetch_payment_methods(
        options?: Partial<{
            pagination: CursorPaginationParams;
        }>,
    ) {
        const data = await unwrapRequest(
            client.GET("/account/billing/payment-methods", {
                params: { query: { ...options?.pagination } },
            }),
        );
        return {
            items: data.methods,
            count: -1,
            page_info: {
                has_next_page: data.has_more,
                end_cursor: data.last_id,
            },
        };
    },

    async fetch_payment_method_client_secret() {
        return await unwrapRequest(
            client.POST("/account/billing/payment-methods"),
        );
    },

    async delete_payment_method(payment_method_id: string) {
        return await unwrapRequest(
            client.DELETE("/account/billing/payment-methods", {
                body: { payment_method_id },
            }),
        );
    },

    async set_payment_default(
        payment_method_id: string,
        payment_method_type: string,
    ) {
        return await unwrapRequest(
            client.POST("/account/billing/payment-methods/default", {
                body: { payment_method_id, payment_method_type },
            }),
        );
    },

    async fetch_invoices(
        options?: Partial<{
            pagination: CursorPaginationParams;
        }>,
    ) {
        const data = await unwrapRequest(
            client.GET("/account/billing/invoices", {
                params: { query: { ...options?.pagination } },
            }),
        );
        return {
            items: data.invoices,
            count: -1,
            page_info: {
                has_next_page: data.has_more,
                end_cursor: data.last_id,
            },
        };
    },

    async pay_invoice(invoice_id: string, payment_method_id: string) {
        return await unwrapRequest(
            client.POST("/account/billing/invoices/pay", {
                body: { invoice_id, payment_method_id },
            }),
        );
    },

    async find_companies(query: string) {
        return await unwrapRequest(
            client.GET("/data/companies", {
                params: { query: { query } },
            }),
        );
    },

    async fetch_company_events(
        ticker: string,
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET("/data/events", {
                params: {
                    query: { ticker, ...pagination },
                },
            }),
        );
    },

    async fetch_multi_company_events(
        tickers: string[],
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET("/data/multi_events", {
                params: {
                    query: { tickers, ...pagination },
                },
            }),
        );
    },

    async fetch_action_history(
        project_component_id: UUID,
        conversation_id: UUID,
        pagination?: CursorPaginationParams,
    ) {
        return await unwrapRequest(
            client.GET(
                "/project-component/{project_component_id}/conversation/{conversation_id}/action/list",
                {
                    params: {
                        path: { project_component_id, conversation_id },
                        query: { ...pagination },
                    },
                },
            ),
        );
    },

    async document_collection_add_documents(
        document_collection_id: UUID,
        body: SchemaBodyAddDocumentsToDocumentCollectionEndpointDocumentCollectionDocumentCollectionIdDocumentPost,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/document",
                { body, params: { path: { document_collection_id } } },
            ),
        );
    },

    async fetch_executive_summary(document_id: UUID) {
        return await unwrapRequest(
            client.GET("/document/{document_id}/executive-summary", {
                params: { path: { document_id } },
            }),
        );
    },

    async fetch_document_finding_groups(
        document_collection_id: UUID,
        document_ids: UUID[],
    ) {
        // chunk requests to make sure urls are not getting too long
        const chunks = balancedChunks(document_ids, 50);
        // request chunks in parallel
        const responses = await Promise.all(
            chunks.map(
                async (chunk) =>
                    await unwrapRequest(
                        client.GET(
                            "/document-collection/{document_collection_id}/finding-group/list",
                            {
                                params: {
                                    path: { document_collection_id },
                                    query: { document_id: chunk },
                                },
                            },
                        ),
                    ),
            ),
        );
        // flatten responses
        return responses.flat();
    },

    async document_collection_remove_document(
        document_collection_id: UUID,
        document_id: UUID,
    ) {
        await unwrapRequest(
            client.DELETE(
                "/document-collection/{document_collection_id}/document/{document_id}",
                { params: { path: { document_collection_id, document_id } } },
            ),
        );
    },

    async document_collection_invalidate_document(
        document_collection_id: UUID,
        document_id: UUID,
    ) {
        await unwrapRequest(
            client.DELETE(
                "/document-collection/{document_collection_id}/finding-group/invalidate-by-doc/{document_id}",
                { params: { path: { document_collection_id, document_id } } },
            ),
        );
    },

    async document_collection_invalidate_by_probe_id(
        document_collection_id: UUID,
        probe_id: UUID,
    ) {
        await unwrapRequest(
            client.DELETE(
                "/document-collection/{document_collection_id}/finding-group/invalidate-by-probe/{probe_id}",
                { params: { path: { document_collection_id, probe_id } } },
            ),
        );
    },

    async fetch_search_companies(body: string) {
        return await unwrapRequest(client.POST("/search/companies", { body }));
    },

    async fetch_search_documents(body: string) {
        return await unwrapRequest(client.POST("/search/documents", { body }));
    },

    async fetch_citation(citation_id: UUID, retry: boolean = false) {
        return await unwrapRequest(
            client.GET("/citation/{citation_id}", {
                params: { path: { citation_id }, query: { retry } },
            }),
        );
    },

    async fetch_grid_view_state(document_collection_id: UUID) {
        return await unwrapRequest(
            client.GET(
                "/document-collection/{document_collection_id}/grid_view_state",
                { params: { path: { document_collection_id } } },
            ),
        );
    },

    async update_grid_view_state(
        document_collection_id: UUID,
        state: SchemaGridViewClientState,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/grid_view_state",
                { body: state, params: { path: { document_collection_id } } },
            ),
        );
    },

    async validate_probe(probe: SchemaProbeInputSchema) {
        return await unwrapRequest(
            client.POST("/probe/validate", {
                body: probe,
            }),
        );
    },

    async generate_section_name(
        report_thread_id: UUID,
        project_component_id: UUID,
        body: {
            prompt?: string | null;
            contexts: (string | SchemaCreateAnalysisProbeSchema)[];
        },
    ) {
        const response = await unwrapRequest(
            client.POST(
                "/report/{report_thread_id}/version/{project_component_id}/section/name",
                {
                    params: {
                        path: { report_thread_id, project_component_id },
                    },
                    body,
                },
            ),
        );
        return response.section_name;
    },

    async fetch_system_probes() {
        return await unwrapRequest(client.GET("/probe/system"));
    },

    async fetch_finding_groups_for_probe(
        document_collection_id: UUID,
        probe_id: UUID,
    ) {
        return await unwrapRequest(
            client.GET(
                "/document-collection/{document_collection_id}/columns/{probe_id}/finding-group/list",
                {
                    params: {
                        path: {
                            document_collection_id,
                            probe_id,
                        },
                    },
                },
            ),
        );
    },

    async retry_failed_finding_group(
        document_collection_id: UUID,
        finding_group_id: UUID,
    ) {
        await unwrapRequest(
            client.DELETE(
                "/document-collection/{document_collection_id}/finding-group/{finding_group_id}/retry-failed",
                {
                    params: {
                        path: { document_collection_id, finding_group_id },
                    },
                },
            ),
        );
    },

    async document_collection_add_column(
        document_collection_id: UUID,
        probe_id: UUID,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/columns/{probe_id}",
                {
                    params: {
                        path: { document_collection_id, probe_id },
                    },
                },
            ),
        );
    },

    async document_collection_create_column(
        document_collection_id: UUID,
        body: SchemaCreateAnalysisProbeSchema,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/columns",
                {
                    params: {
                        path: { document_collection_id },
                    },
                    body,
                },
            ),
        );
    },

    async document_collection_update_column(
        document_collection_id: UUID,
        probe_id: UUID,
        body: SchemaCreateAnalysisProbeSchema,
    ) {
        return await unwrapRequest(
            client.PUT(
                "/document-collection/{document_collection_id}/columns/{probe_id}",
                {
                    params: {
                        path: { document_collection_id, probe_id },
                    },
                    body,
                },
            ),
        );
    },

    async document_collection_remove_column(
        document_collection_id: UUID,
        probe_id: UUID,
    ) {
        return await unwrapRequest(
            client.DELETE(
                "/document-collection/{document_collection_id}/columns/{probe_id}",
                {
                    params: {
                        path: { document_collection_id, probe_id },
                    },
                },
            ),
        );
    },

    async app_version() {
        return await unwrapRequest(client.GET("/version"));
    },

    async fetch_probes() {
        return await unwrapRequest(client.GET("/probe"));
    },

    async star_probe(probe_id: UUID) {
        await unwrapRequest(
            client.POST("/probe/{probe_id}/star", {
                params: { path: { probe_id } },
            }),
        );
    },
    async unstar_probe(probe_id: UUID) {
        await unwrapRequest(
            client.DELETE("/probe/{probe_id}/star", {
                params: { path: { probe_id } },
            }),
        );
    },

    async create_synthesis(
        document_collection_id: UUID,
        body: SchemaBodyAddSynthesisToDocumentCollectionDocumentCollectionDocumentCollectionIdSynthesisPost,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/synthesis",
                {
                    params: { path: { document_collection_id } },
                    body,
                },
            ),
        );
    },

    async fetch_synthesis(
        document_collection_id: UUID,
        finding_group_id: UUID,
    ) {
        return await unwrapRequest(
            client.GET(
                "/document-collection/{document_collection_id}/synthesis/{finding_group_id}",
                {
                    params: {
                        path: { document_collection_id, finding_group_id },
                    },
                },
            ),
        );
    },

    async fetch_synthesis_list(
        document_collection_id: UUID,
        body: objMap.ObjMap<UUID[]>,
    ) {
        return await unwrapRequest(
            client.POST(
                "/document-collection/{document_collection_id}/synthesis/recent",
                {
                    params: {
                        path: { document_collection_id },
                    },
                    body,
                },
            ),
        );
    },

    async regenerate_synthesis(
        document_collection_id: UUID,
        finding_group_id: UUID,
    ) {
        return await unwrapRequest(
            client.PUT(
                "/document-collection/{document_collection_id}/synthesis/{finding_group_id}/rerun",
                {
                    params: {
                        path: { document_collection_id, finding_group_id },
                    },
                },
            ),
        );
    },

    async fetch_report(report_thread_id: UUID) {
        return await unwrapRequest(
            client.GET("/report/{report_thread_id}", {
                params: { path: { report_thread_id } },
            }),
        );
    },

    async create_report(
        project_id: UUID,
        project_component_id: UUID,
        body: SchemaCreateReportForDocumentCollectionRequest,
    ) {
        return await unwrapRequest(
            client.POST(
                "/project/{project_id}/document-collection/{project_component_id}/report",
                {
                    params: { path: { project_id, project_component_id } },
                    body,
                },
            ),
        );
    },

    async create_report_from_blueprint(
        project_id: UUID,
        project_component_id: UUID,
        blueprint_version_id: UUID,
    ) {
        return await unwrapRequest(
            client.POST(
                "/project/{project_id}/document-collection/{project_component_id}/report",
                {
                    params: { path: { project_id, project_component_id } },
                    body: { blueprint_version_id },
                },
            ),
        );
    },

    async report_add_section(
        report_thread_id: UUID,
        project_component_id: UUID,
        body: string | SchemaCreateSynthesisProbeSchema,
    ) {
        return await unwrapRequest(
            client.POST(
                "/report/{report_thread_id}/version/{project_component_id}/section",
                {
                    params: {
                        path: { report_thread_id, project_component_id },
                    },
                    body,
                },
            ),
        );
    },

    async report_update_section(
        report_thread_id: UUID,
        project_component_id: UUID,
        probe_id: UUID,
        body: SchemaCreateSynthesisProbeSchema,
    ) {
        return await unwrapRequest(
            client.PUT(
                "/report/{report_thread_id}/version/{project_component_id}/section/{probe_id}",
                {
                    params: {
                        path: {
                            report_thread_id,
                            project_component_id,
                            probe_id,
                        },
                    },
                    body,
                },
            ),
        );
    },

    async report_delete_section(
        report_thread_id: UUID,
        project_component_id: UUID,
        probe_id: UUID,
    ) {
        return await unwrapRequest(
            client.DELETE(
                "/report/{report_thread_id}/version/{project_component_id}/section/{probe_id}",
                {
                    params: {
                        path: {
                            report_thread_id,
                            project_component_id,
                            probe_id,
                        },
                    },
                },
            ),
        );
    },

    async fetch_finding(project_component_id: UUID, finding_id: UUID) {
        return await unwrapRequest(
            client.GET(
                "/project-component/{project_component_id}/document-finding/{finding_id}",
                {
                    params: {
                        path: {
                            project_component_id,
                            finding_id,
                        },
                    },
                },
            ),
        );
    },

    async fetch_blueprints() {
        return await unwrapRequest(client.GET("/blueprint"));
    },

    async save_blueprint(
        blueprint_version_id: UUID,
        body: SchemaBlueprintDisplayInfo,
    ) {
        await unwrapRequest(
            client.POST("/blueprint/{blueprint_version_id}/star", {
                params: {
                    path: {
                        blueprint_version_id,
                    },
                },
                body,
            }),
        );
    },

    async delete_blueprint(blueprint_version_id: UUID) {
        await unwrapRequest(
            client.DELETE("/blueprint/{blueprint_version_id}/star", {
                params: {
                    path: {
                        blueprint_version_id,
                    },
                },
            }),
        );
    },

    async share_report(
        project_id: UUID,
        report_thread_id: UUID,
        body: SchemaCreateShareRequest,
    ) {
        return await unwrapRequest(
            client.POST(
                "/project/{project_id}/report-thread/{report_thread_id}/share",
                {
                    params: {
                        path: {
                            project_id,
                            report_thread_id,
                        },
                    },
                    body,
                },
            ),
        );
    },

    async update_share(
        project_id: UUID,
        report_thread_id: UUID,
        body: SchemaUpdateShareRequest,
    ) {
        return await unwrapRequest(
            client.PATCH(
                "/project/{project_id}/report-thread/{report_thread_id}/share",
                {
                    params: {
                        path: {
                            project_id,
                            report_thread_id,
                        },
                    },
                    body,
                },
            ),
        );
    },

    async update_project_component_metadata(
        project_component_id: UUID,
        metadata: { title: string; description?: string },
        options?: Partial<{ update_project_name: boolean }>,
    ) {
        return await unwrapRequest(
            client.PUT("/project-component/{project_component_id}/metadata", {
                params: {
                    path: { project_component_id },
                    query: {
                        update_project_name: options?.update_project_name,
                    },
                },
                body: metadata,
            }),
        );
    },

    async fetch_projects(options?: { pagination?: CursorPaginationParams }) {
        return unwrapRequest(
            client.GET("/project", {
                params: { query: options?.pagination },
            }),
        );
    },

    async fetch_project(project_id: UUID) {
        return unwrapRequest(
            client.GET("/project/{project_id}", {
                params: { path: { project_id } },
            }),
        );
    },

    async create_project(body: SchemaBodyCreateProjectProjectPost) {
        return unwrapRequest(client.POST("/project", { body }));
    },

    async delete_project(project_id: UUID) {
        return unwrapRequest(
            client.DELETE("/project/{project_id}", {
                params: { path: { project_id } },
            }),
        );
    },

    async fetch_project_components(
        project_id: UUID,
        type?: ProjectComponentType,
    ) {
        return unwrapRequest(
            client.GET("/project/{project_id}/components", {
                params: { path: { project_id }, query: { type } },
            }),
        );
    },

    async fetch_project_component(
        project_id: UUID,
        project_component_id: UUID,
    ) {
        return await unwrapRequest(
            client.GET(
                "/project/{project_id}/component/{project_component_id}",
                { params: { path: { project_id, project_component_id } } },
            ),
        );
    },

    async fetch_report_finding_groups(
        report_thread_id: UUID,
        project_component_id: UUID,
    ) {
        return await unwrapRequest(
            client.GET(
                "/report/{report_thread_id}/version/{project_component_id}",
                {
                    params: {
                        path: { report_thread_id, project_component_id },
                    },
                },
            ),
        );
    },

    async update_project_metadata(
        project_id: UUID,
        body: SchemaUpdateProjectMetadataRequest,
    ) {
        return await unwrapRequest(
            client.PUT("/project/{project_id}/metadata", {
                params: { path: { project_id } },
                body,
            }),
        );
    },

    async report_section_rerun(
        report_thread_id: UUID,
        project_component_id: UUID,
        probe_id: UUID,
    ) {
        return await unwrapRequest(
            client.PUT(
                "/report/{report_thread_id}/version/{project_component_id}/section/{probe_id}/rerun",
                {
                    params: {
                        path: {
                            report_thread_id,
                            project_component_id,
                            probe_id,
                        },
                    },
                },
            ),
        );
    },

    async fetch_suggested_section_prompts(
        report_thread_id: UUID,
        project_component_id: UUID,
        body: {
            prompt?: string | null;
            contexts: (string | SchemaCreateAnalysisProbeSchema)[];
        },
    ) {
        return await unwrapRequest(
            client.POST(
                "/report/{report_thread_id}/version/{project_component_id}/section/prompt-suggestions",
                {
                    params: {
                        path: {
                            report_thread_id,
                            project_component_id,
                        },
                    },
                    body,
                },
            ),
        );
    },

    async delete_report(project_id: UUID, project_component_id: UUID) {
        return await unwrapRequest(
            client.DELETE(
                "/project/{project_id}/component/{project_component_id}",
                { params: { path: { project_id, project_component_id } } },
            ),
        );
    },

    async fetch_mfa_status() {
        return await unwrapRequest(client.GET("/auth/mfa/enrollment"));
    },

    async enroll_mfa() {
        return await unwrapRequest(
            client.POST("/auth/mfa/enrollment", {
                body: { send_email: false },
            }),
        );
    },

    async disable_mfa() {
        return await unwrapRequest(client.DELETE("/auth/mfa/enrollment"));
    },
};
