import { RouterProvider, createRouter } from "@tanstack/react-router";
import { StrictMode, Suspense } from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";

import { PageError } from "@/app/page-error";
import { PageLoader } from "@/app/page-loader";
import { ErrorBoundary } from "@/components/error-boundary";
import { Favicon } from "@/components/favicon";
import { FullStoryConfig } from "@/components/full-story-config";
import { LoginAsBanner } from "@/components/login-as-banner";
import { OfflineIndicator } from "@/components/offline-indicator";
import { OutdatedBanner } from "@/components/outdated-banner";
import { SentryConfig } from "@/components/sentry-config";
import { Toaster } from "@/components/ui/sonner";
import { useConfig } from "@/hooks/use-config";
import { AppProvider } from "@/provider/app";
import { useAuth } from "@/provider/auth";
import { routeTree } from "@/routeTree.gen";

import "@/styles/global.css";

const router = createRouter({
    routeTree,
    defaultPreload: "intent",
    scrollRestoration: true,
    defaultPendingMs: 0,
    context: {
        auth: undefined!,
        config: undefined!,
    },
});

declare module "@tanstack/react-router" {
    interface Register {
        router: typeof router;
    }
    interface HistoryState {
        referrer?: "report" | "grid";
    }
}

function App() {
    const auth = useAuth();
    const config = useConfig();
    useEffect(() => {
        router.invalidate();
    }, [auth.isAuthenticated]);
    return <RouterProvider router={router} context={{ auth, config }} />;
}

ReactDOM.createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ErrorBoundary
            fallback={({ error, componentStack, resetError }) => (
                <PageError
                    error={error instanceof Error ? error : undefined}
                    reset={resetError}
                    info={{ componentStack }}
                />
            )}
        >
            <Suspense fallback={<PageLoader />}>
                <AppProvider>
                    <App />
                    <OfflineIndicator />
                    <OutdatedBanner />
                    <LoginAsBanner />
                    <Toaster richColors />
                    <Favicon />
                    <SentryConfig router={router} />
                    <FullStoryConfig />
                </AppProvider>
            </Suspense>
        </ErrorBoundary>
    </StrictMode>,
);
