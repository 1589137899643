import { createFileRoute, notFound } from "@tanstack/react-router";

import { ManageUsersSettings } from "@/app/settings/manage-users";
import { ManageUserContextProvider } from "@/context/manage-user-context-provider";
import { canSeeManageUsersSettings } from "@/utils/user";

export const Route = createFileRoute("/_app/settings/manage-users")({
    beforeLoad({ context }) {
        if (!canSeeManageUsersSettings(context.auth.user)) {
            throw notFound();
        }
    },
    component: () => (
        <ManageUserContextProvider>
            <ManageUsersSettings />
        </ManageUserContextProvider>
    ),
});
