import { ArrowLeftRightIcon, KeyRoundIcon } from "lucide-react";
import { createPortal } from "react-dom";

import { api } from "@/api/rest";
import { AsyncButton } from "@/components/async-button";
import { useAsyncState } from "@/hooks/use-async-state";
import { useUser } from "@/provider/auth";
import { getUserName } from "@/utils/string-helpers";
import { isLoggedInAs } from "@/utils/user";

export function LoginAsBanner() {
    const user = useUser({ nullable: true });
    const action = useAsyncState(api.admin_switch_back, {
        onSuccess: () =>
            window.location.assign(`/admin/accounts/${user?.account_id}`),
        onError: () => window.location.assign("/api/auth/logout"),
    });

    if (!user || !isLoggedInAs(user)) {
        return null;
    }

    return createPortal(
        <div className="bg-destructive text-destructive-foreground pointer-events-auto fixed top-0 left-1/2 z-[9999] flex -translate-x-1/2 items-center gap-4 rounded-b-md px-10 py-2 text-sm">
            <KeyRoundIcon className="size-4" />
            <span>
                Logged In as{" "}
                <span className="font-headline font-bold">
                    {getUserName(user)}
                </span>
            </span>
            <AsyncButton size="xs" action={action}>
                <ArrowLeftRightIcon />
                Switch back
            </AsyncButton>
        </div>,
        document.body,
    );
}
