import {
    createFileRoute,
    invariant,
    Navigate,
    notFound,
    redirect,
} from "@tanstack/react-router";

import { ConfirmSubscription } from "@/app/paywall/confirm-subscription";
import { ConfirmTrial } from "@/app/paywall/confirm-trial";
import { Lapsed } from "@/app/paywall/lapsed";
import { Payment } from "@/app/paywall/payment";
import { ProvideBillingDetails } from "@/app/paywall/provide-billing-details";
import { StripeElements } from "@/app/paywall/stripe-elements";
import { Headline } from "@/components/headline";
import { Checkout } from "@/components/paywall/checkout";
import { StripeContextProvider } from "@/context/stripe-context-provider";
import { CustomerStatus } from "@/openapi-schema";
import { useUser } from "@/provider/auth";
import { isUserOfPaywalledOrgAccount } from "@/utils/user";

export const Route = createFileRoute("/_paywall/paywall")({
    beforeLoad({ context }) {
        invariant(context.auth.isAuthenticated, "User must be authenticated");
        const status = context.auth.user.status;
        switch (status) {
            case CustomerStatus.trial_pending:
            case CustomerStatus.subscription_confirmation_needed:
            case CustomerStatus.billing_details_required:
            case CustomerStatus.payment_required:
            case CustomerStatus.lapsed:
                return;
            case CustomerStatus.subscription_pending:
                // Self serve signup is not supported
                throw notFound();
            case CustomerStatus.active:
            case CustomerStatus.trialing:
                throw redirect({ to: "/" });
            default:
                status satisfies never;
                throw notFound();
        }
    },
    component: RouteComponent,
});

function RouteComponent() {
    const user = useUser();
    if (isUserOfPaywalledOrgAccount(user)) {
        return (
            <>
                <Headline level={2} highlighted>
                    Account Not Active
                </Headline>
                <p className="text-xl">
                    Please reach out to your organization's account
                    administrator to complete account setup.
                </p>
            </>
        );
    }
    switch (user.status) {
        case CustomerStatus.subscription_confirmation_needed:
            return (
                <StripeContextProvider>
                    <Checkout>
                        <ConfirmSubscription />
                    </Checkout>
                </StripeContextProvider>
            );
        case CustomerStatus.payment_required:
            return (
                <StripeContextProvider>
                    <Checkout>
                        <StripeElements paymentIntent>
                            <Payment />
                        </StripeElements>
                    </Checkout>
                </StripeContextProvider>
            );
        case CustomerStatus.trial_pending:
            return (
                <StripeContextProvider>
                    <Checkout title="Confirm Trial">
                        <ConfirmTrial />
                    </Checkout>
                </StripeContextProvider>
            );
        case CustomerStatus.billing_details_required:
            return (
                <StripeContextProvider>
                    <Checkout>
                        <ProvideBillingDetails />
                    </Checkout>
                </StripeContextProvider>
            );
        case CustomerStatus.lapsed:
            return <Lapsed />;
        default:
            return <Navigate to="/" />;
    }
}
