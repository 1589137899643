import { createFileRoute, useNavigate } from "@tanstack/react-router";

import { AdminDashboard } from "@/app/admin/dashboard";
import { maybeString } from "@/utils/string-helpers";

type SearchParams = {
    start?: string;
    end?: string;
    percentile?: string;
};

export const Route = createFileRoute("/_app/admin/_admin/")({
    validateSearch: (search: Record<string, unknown>): SearchParams => ({
        start: maybeString(search.start),
        end: maybeString(search.end),
        percentile: maybeString(search.percentile),
    }),
    component: RouteComponent,
});

function RouteComponent() {
    const search = Route.useSearch();
    const navigate = useNavigate({ from: Route.fullPath });
    return <AdminDashboard navigate={navigate} {...search} />;
}
