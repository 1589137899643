import { ThemeProvider } from "next-themes";
import { PropsWithChildren } from "react";

import { AuthProvider } from "@/provider/auth";
import { ConfigProvider } from "@/provider/config";

export function AppProvider(props: PropsWithChildren) {
    return (
        <ConfigProvider>
            <AuthProvider>
                <ThemeProvider
                    storageKey="color-scheme"
                    attribute="class"
                    enableSystem
                >
                    {props.children}
                </ThemeProvider>
            </AuthProvider>
        </ConfigProvider>
    );
}
