import { Loader2Icon } from "lucide-react";

import { BrightwaveLogo } from "@/components/brightwave-logo";
import { Copyright } from "@/components/copyright";

export function PageLoader() {
    return (
        <div className="bg-logo-clipped bg-55 flex min-h-screen flex-col items-center justify-between bg-left-top bg-no-repeat pt-40 pb-4">
            <BrightwaveLogo className="h-auto w-52" />
            <div className="grow py-20">
                <Loader2Icon className="text-muted-foreground size-8 animate-spin" />
            </div>
            <Copyright />
        </div>
    );
}
