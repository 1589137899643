import { createFileRoute } from "@tanstack/react-router";

import { Signup } from "@/app/auth/signup";
import { InviteType, SchemaInviteAcceptanceBase } from "@/openapi-schema";
import { maybeString } from "@/utils/string-helpers";

export const Route = createFileRoute("/_auth/signup")({
    validateSearch(
        search: Record<string, unknown>,
    ): SchemaInviteAcceptanceBase | undefined {
        const username = maybeString(search.email);
        const code = maybeString(search.code);
        const invite_type = maybeString(search.invite_type);
        if (username && code && invite_type && invite_type in InviteType) {
            return {
                username,
                code,
                invite_type: InviteType[invite_type as keyof typeof InviteType],
            };
        }
        return undefined;
    },
    component: RouteComponent,
});

function RouteComponent() {
    const inviteInfo = Route.useSearch();
    return <Signup inviteInfo={inviteInfo} />;
}
