import { createFileRoute } from "@tanstack/react-router";

import { V2Dashboard } from "@/app/dashboard";
import { DashboardRoot } from "@/app/dashboard/root";
import { useV3 } from "@/hooks/use-v3";

export const Route = createFileRoute("/_app/dashboard")({
    component: RouteComponent,
});

function RouteComponent() {
    const [v3] = useV3();
    if (v3) {
        return <DashboardRoot />;
    }
    return <V2Dashboard />;
}
