import { createContext, PropsWithChildren, use, useState } from "react";
import { useEffect } from "react";
import useSWR, { KeyedMutator } from "swr";

import { HttpStatusCode } from "@/api/http-status-codes";
import { api, HttpError } from "@/api/rest";
import { PageLoader } from "@/app/page-loader";
import { SchemaPermissionsUser } from "@/openapi-schema";
import { nonNull } from "@/utils/fn";

export type AuthContext =
    | {
          isAuthenticated: false;
          user: null;
          update: KeyedMutator<SchemaPermissionsUser | null>;
      }
    | {
          isAuthenticated: true;
          user: SchemaPermissionsUser;
          update: KeyedMutator<SchemaPermissionsUser | null>;
      };

const AuthContext = createContext<AuthContext | null>(null);

export function AuthProvider(props: PropsWithChildren) {
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const { data: user, mutate } = useSWR(
        "auth",
        async () => {
            try {
                return await api.fetch_user_info();
            } catch (err) {
                if (
                    err instanceof HttpError &&
                    err.status === HttpStatusCode.Unauthorized
                ) {
                    return null;
                }
                throw err;
            }
        },
        { suspense: true },
    );

    // subscribe to 401 & 403 errors and auto-logout
    useEffect(() => {
        if (nonNull(user) && isLoggingOut === false) {
            return api.onNotAuthenticated(() => {
                if (!isLoggingOut) {
                    setIsLoggingOut(true);
                    window.location.assign("/api/auth/logout");
                }
            });
        }
    }, [user]);

    if (isLoggingOut) {
        return <PageLoader />;
    }

    return (
        <AuthContext
            value={
                user === null
                    ? {
                          user: null,
                          isAuthenticated: false,
                          update: mutate,
                      }
                    : {
                          user,
                          isAuthenticated: true,
                          update: mutate,
                      }
            }
            {...props}
        />
    );
}

export function useAuth(): AuthContext {
    const context = use(AuthContext);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
}

type Opts = {
    nullable: boolean;
};

function assert<T>(val: T | null | undefined, message?: string): T {
    if (val === null || val === undefined) {
        throw new Error(message);
    }
    return val;
}

type Ret<T extends Opts> = T["nullable"] extends true
    ? SchemaPermissionsUser | null
    : SchemaPermissionsUser;

export const useUser = <T extends Opts>(
    opts: T = { nullable: true } as T,
): Ret<T> => {
    const ctx = use(AuthContext);
    if (opts.nullable) {
        return ctx?.user as Ret<T>;
    }
    return assert(ctx?.user, "User not found");
};
