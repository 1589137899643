import { useLocalStorage } from "@/hooks/use-local-storage";
import { useUser } from "@/provider/auth";
import { emptyFunction } from "@/utils/empty-function";
import { isUserSuperAdmin } from "@/utils/user";

export const useDevMode = (): [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
] => {
    const user = useUser({ nullable: true });
    const [devMode, setDevMode] = useLocalStorage({
        key: "dev_mode",
        deserialize: (val) => (val === "on" ? true : false),
        serialize: (val) => (val ? "on" : "off"),
    });

    return user && isUserSuperAdmin(user)
        ? ([devMode, setDevMode] as const)
        : ([false, emptyFunction] as const);
};
