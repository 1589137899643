import hash from "stable-hash";

export const pipe =
    <Tin extends unknown[], T, Tout>(
        f1: (...args: Tin) => T,
        f2: (arg: T) => Tout,
    ): ((...args: Tin) => Tout) =>
    (...args: Tin) =>
        f2(f1(...args));

export const bind =
    <T, A, R>(func: (arg: T, ...args: A[]) => R, arg: T) =>
    (...args: A[]): R =>
        func(arg, ...args);

export const not =
    <T extends unknown[]>(fn: (...args: T) => boolean) =>
    (...args: T): boolean =>
        !fn(...args);

export const memoize = <T, A extends unknown[]>(
    fn: (...args: A) => T,
): ((...args: A) => T) => {
    const cache = new Map<string, T>();
    return (...args: A): T => {
        const key = hash(args);
        if (!cache.has(key)) {
            cache.set(key, fn(...args));
        }
        return cache.get(key)!;
    };
};

export const nonNull = <T>(value: T | null | undefined): value is T =>
    value !== null && value !== undefined;

export const isNull = <T>(
    value: T | null | undefined,
): value is null | undefined => value === null || value === undefined;

export const debounce = <T extends unknown[], R>(
    func: (...args: T) => R,
    delay: number,
): ((...args: T) => void) => {
    let timeoutId: number | null = null;
    return (...args: T): void => {
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(() => {
            func(...args);
            timeoutId = null;
        }, delay);
    };
};

export const maybeMap = <T, U>(
    value: T | null | undefined,
    mapper: (value: T) => U,
): U | null | undefined => {
    if (nonNull(value)) {
        return mapper(value);
    }
    return value;
};
