import { useReducer } from "react";

enum ActionType {
    submit,
    error,
    success,
    reset,
}

interface TAction<Tk extends ActionType> {
    type: Tk;
}

interface TActionWithPayload<Tk extends ActionType, Payload>
    extends TAction<Tk> {
    payload: Payload;
}

type Action<T> =
    | TAction<ActionType.reset>
    | TAction<ActionType.submit>
    | TActionWithPayload<ActionType.error, Error>
    | TActionWithPayload<ActionType.success, T>;

enum StateType {
    initial = "initial",
    submitting = "submitting",
    error = "error",
    success = "success",
}

type State<T> =
    | { type: StateType.initial }
    | { type: StateType.submitting }
    | { type: StateType.error; error: Error }
    | { type: StateType.success; value: T };

const reducer = <T>(_: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
        case ActionType.submit:
            return { type: StateType.submitting };
        case ActionType.error:
            return { type: StateType.error, error: action.payload };
        case ActionType.success:
            return { type: StateType.success, value: action.payload };
        case ActionType.reset:
            return { type: StateType.initial };
    }
};

type AsyncFunction<Args extends unknown[], R> = (...args: Args) => Promise<R>;

export type UseAsyncState<Args extends unknown[], R> = {
    submit: (...args: Args) => Promise<void>;
    state: State<R>;
    isSubmitting: boolean;
    getEventHandler: (...args: Args) => () => void;
    error: Error | undefined;
    data: R | undefined;
    reset: () => void;
};

export type UseAsyncOptions<R> = {
    onRequest: () => void;
    onComplete: (value: R | undefined, error: Error | undefined) => void;
    onSuccess: (value: R) => void;
    onError: (error: Error) => void;
    onReset: () => void;
};

export const useAsyncState = <Args extends unknown[], R>(
    fn: AsyncFunction<Args, R>,
    options?: Partial<UseAsyncOptions<R>>,
): UseAsyncState<Args, R> => {
    const [state, dispatch] = useReducer(reducer<R>, {
        type: StateType.initial,
    });

    const isSubmitting = state.type === StateType.submitting;
    const submit = async (...args: Args) => {
        if (isSubmitting) return;
        dispatch({ type: ActionType.submit });
        options?.onRequest?.();
        try {
            const value = await fn(...args);
            dispatch({ type: ActionType.success, payload: value });
            options?.onSuccess?.(value);
            options?.onComplete?.(value, undefined);
        } catch (error) {
            dispatch({ type: ActionType.error, payload: error as Error });
            options?.onError?.(error as Error);
            options?.onComplete?.(undefined, error as Error);
        }
    };
    const reset = () => {
        dispatch({ type: ActionType.reset });
        options?.onReset?.();
    };

    return {
        submit,
        state,
        isSubmitting,
        getEventHandler:
            (...args: Args) =>
            () => {
                submit(...args);
            },
        error: state.type === StateType.error ? state.error : undefined,
        data: state.type === StateType.success ? state.value : undefined,
        reset,
    };
};
