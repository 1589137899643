import { createRootRouteWithContext, Outlet } from "@tanstack/react-router";
import { lazy } from "react";
import { Suspense } from "react";

import { ConfigurationMeta } from "@/api/types";
import { PageError } from "@/app/page-error";
import { PageLoader } from "@/app/page-loader";
import { PageNotFound } from "@/app/page-not-found";
import { Debugger } from "@/components/debug/debugger";
import { AuthContext } from "@/provider/auth";

interface RouterContext {
    auth: AuthContext;
    config: ConfigurationMeta;
}

export const Route = createRootRouteWithContext<RouterContext>()({
    component: AppRoot,
    notFoundComponent: PageNotFound,
    errorComponent: PageError,
    pendingComponent: PageLoader,
});

function AppRoot() {
    return (
        <>
            <Outlet />
            <Debugger>
                <Suspense>
                    <TanStackRouterDevtools />
                </Suspense>
            </Debugger>
        </>
    );
}

const TanStackRouterDevtools = import.meta.env.PROD
    ? () => null
    : lazy(() =>
          import("@tanstack/router-devtools").then((res) => ({
              default: res.TanStackRouterDevtools,
          })),
      );
