/*
 * MUST mirror the Feature Flag name on Launch Darkly
 */
export enum FeatureFlagBool {
    FORCE_V3 = "FORCE_V3",
    FULLSTORY_ENABLED = "FULLSTORY_ENABLED",
    INQUIRY_ENABLED = "INQUIRY_ENABLED",
}

export enum FeatureFlagNumber {
    REPORT_MAX_DOCUMENTS = "REPORT_MAX_DOCUMENTS",
}

export enum FeatureFlagJSON {
    SUPPORTED_UPLOAD_MIME_TYPES = "SUPPORTED_UPLOAD_MIME_TYPES",
}

interface FeatureFlagJSONContent {
    [FeatureFlagJSON.SUPPORTED_UPLOAD_MIME_TYPES]: string[];
}

export type FeatureFlags = Readonly<
    {
        [key in FeatureFlagBool]: boolean;
    } & {
        [key in FeatureFlagNumber]: number;
    } & FeatureFlagJSONContent
>;
