import { createFileRoute, notFound } from "@tanstack/react-router";
import { Suspense, use } from "react";
import { validate } from "uuid";

import { api } from "@/api/rest";
import { AnalysisProbe, DocumentInfo } from "@/api/type-extensions";
import { GridViewRoot } from "@/app/grid-view/root";
import { PageSkeleton } from "@/app/page-skeleton";
import { ReportRoot } from "@/app/report/root";
import { GridViewContentSkeleton } from "@/components/analyze/skeleton";
import {
    DocumentCollectionType,
    ReportType,
    SchemaBlueprintWithUserInfo,
    SchemaDocumentCollection,
    SchemaDocumentFindingGroup,
    SchemaProjectDetail,
    SchemaReport,
    SchemaSynthesisFindingGroup,
} from "@/openapi-schema";
import { VersionedState } from "@/utils/grid-view-client-state";

type GridViewPageTypes = {
    project: SchemaProjectDetail;
    info: SchemaDocumentCollection;
    blueprint: SchemaBlueprintWithUserInfo;
    documents: readonly DocumentInfo[];
    clientStatePromise: Promise<VersionedState<Record<string, unknown>>>;
    availableColumnsPromise: Promise<readonly AnalysisProbe[]>;
    documentFindingGroupsPromise: Promise<
        readonly SchemaDocumentFindingGroup[]
    >;
};

type Page =
    | ({ type: "gridview" } & GridViewPageTypes)
    | {
          type: "report";
          project: SchemaProjectDetail;
          info: SchemaReport;
          blueprint: SchemaBlueprintWithUserInfo;
          documents: readonly DocumentInfo[];
          synthesisFindingGroups: readonly SchemaSynthesisFindingGroup[];
      };

export const Route = createFileRoute(
    "/_app/_v3/project/$projectID/_project/c/$componentID",
)({
    beforeLoad({ params }) {
        if (!validate(params.componentID)) {
            throw notFound();
        }
    },
    async loader({ params }): Promise<Page> {
        const [project, { info, blueprint, documents }] = await Promise.all([
            api.fetch_project(params.projectID),
            api.fetch_project_component(params.projectID, params.componentID),
        ]);
        switch (info.type) {
            case DocumentCollectionType.document_collection:
                return {
                    type: "gridview",
                    project,
                    info,
                    blueprint,
                    documents: documents.map(({ info }) => info),
                    clientStatePromise: api.fetch_grid_view_state(info.id),
                    availableColumnsPromise: api.fetch_system_probes(),
                    documentFindingGroupsPromise:
                        api.fetch_document_finding_groups(
                            info.id,
                            documents.map(({ info }) => info.id),
                        ),
                };
            case ReportType.report:
                return {
                    type: "report",
                    project,
                    info,
                    blueprint,
                    documents: documents.map(({ info }) => info),
                    synthesisFindingGroups:
                        await api.fetch_report_finding_groups(
                            info.thread_id,
                            info.id,
                        ),
                };
            default:
                info satisfies never;
                throw notFound();
        }
    },
    component: RouteComponent,
});

function RouteComponent() {
    const data = Route.useLoaderData();
    switch (data.type) {
        case "gridview":
            return (
                <Suspense
                    fallback={
                        <PageSkeleton>
                            <GridViewContentSkeleton />
                        </PageSkeleton>
                    }
                >
                    <GridViewRouteComponent {...data} />
                </Suspense>
            );
        case "report":
            return <ReportRoot {...data} />;
        default:
            data satisfies never;
            throw notFound();
    }
}

function GridViewRouteComponent(props: GridViewPageTypes) {
    const {
        clientStatePromise,
        availableColumnsPromise,
        documentFindingGroupsPromise,
        ...rest
    } = props;
    const clientState = use(clientStatePromise);
    const availableColumns = use(availableColumnsPromise);
    const documentFindingGroups = use(documentFindingGroupsPromise);

    return (
        <GridViewRoot
            {...{
                clientState,
                availableColumns,
                documentFindingGroups,
                ...rest,
            }}
        />
    );
}
