import {
    createFileRoute,
    Link,
    notFound,
    Outlet,
} from "@tanstack/react-router";
import { Loader2Icon } from "lucide-react";
import { Suspense } from "react";

import { Button } from "@/components/ui/button";
import { isUserSuperAdmin } from "@/utils/user";

export const Route = createFileRoute("/_app/admin/_admin")({
    beforeLoad: async ({ context }) => {
        if (!context.auth.user || !isUserSuperAdmin(context.auth.user)) {
            throw notFound();
        }
    },
    component: RouteComponent,
});

function RouteComponent() {
    return (
        <div className="relative grow overflow-scroll">
            <div className="container">
                <div className="-mx-4 flex gap-4 py-4">
                    <Button
                        variant="ghost"
                        className="[.active]:bg-muted"
                        asChild
                    >
                        <Link
                            to="/admin"
                            activeOptions={{
                                exact: true,
                                includeSearch: false,
                            }}
                        >
                            Dashboard
                        </Link>
                    </Button>
                    <Button
                        variant="ghost"
                        className="[.active]:bg-muted"
                        asChild
                    >
                        <Link to="/admin/accounts">Accounts</Link>
                    </Button>
                    <Button
                        variant="ghost"
                        className="[.active]:bg-muted"
                        asChild
                    >
                        <Link to="/admin/chat-messages">Chat Messages</Link>
                    </Button>
                </div>
                <Suspense
                    fallback={
                        <div className="flex items-center justify-center p-4">
                            <Loader2Icon className="size-4 animate-spin" />
                        </div>
                    }
                >
                    <Outlet />
                </Suspense>
            </div>
        </div>
    );
}
