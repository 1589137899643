import { createFileRoute, notFound } from "@tanstack/react-router";
import { validate } from "uuid";

export const Route = createFileRoute("/_app/_v3/project/$projectID/_project")({
    beforeLoad({ params }) {
        if (!validate(params.projectID)) {
            throw notFound();
        }
    },
});
